/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';


function nrFormated(nr: number) {
  let output = nr.toFixed(2);
  if (output.length > 6)
    output = output.substring(0, output.length - 6) + ' ' + output.substring(output.length - 6);
  if (output.length > 10)
    output = output.substring(0, output.length - 10) + ' ' + output.substring(output.length - 10);

  return output;
}

export default function OrderTable(props) {
  const comp_id = props.compID;
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  // ------------------ wczytujemy towary z bazy -----------
  const [invoices, setInvoices] = React.useState([]);

  function getMyInvoices() {
    fetch('https://be.factoorian.pl/invoices/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setInvoices(JSON.parse(data));
        console.log(JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getMyInvoices();
  }, []);

  // ------------------ wczytujemy kontrahentów z bazy -----------
  const [contractors, setContractors] = React.useState([]);

  function getContractors() {
    fetch('https://be.factoorian.pl/contractors/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setContractors(JSON.parse(data));
        console.log(JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getContractors();
  }, []);

  const [binary, setBinary] = React.useState<any>();
  const [picLoaded, setPicLoaded] = React.useState<boolean>(false);
  
  function getFile(fName: string) {
    fetch('https://be.factoorian.pl/pdfs/' + fName)
      .then(response => {
        let output = response.blob();
        console.log(response);
        return output;
      })
      .then(data => {
//        let plik = new File([data], "nazwa.pdf");
        
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'nowyplik.pdf';
        link.click();
        window.URL.revokeObjectURL(link.href);


//        setBinary(data);
//        setPicLoaded(true);
//        console.log("Parsing file binary:", data);
      });
  }

// ----------------------------------------------------------------------
  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          //         zIndex: 9995,
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Stack direction='row' spacing={1}>
              <HomeRoundedIcon color='primary' fontSize='small' />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Faktury
              </Typography>
              <ChevronRightRoundedIcon color='primary' fontSize="small" />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Moje faktury
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h3" component="h1" sx={{ mt: 1, mb: 2 }}>
            Moje faktury
          </Typography>
        </Box>
      </Box>

      {/*      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      > */}
      <Card>
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>&nbsp;</th>
              <th style={{ width: 120, padding: '12px 6px', textAlign: 'center' }}>Numer</th>
              <th style={{ width: 140, padding: '12px 6px', textAlign: 'center' }}>Data wystawienia</th>
              <th style={{ width: 140, padding: '12px 6px', textAlign: 'center' }}>Data sprzedazy</th>
              <th style={{ width: 240, padding: '12px 6px', textAlign: 'center' }}>Kontrahent</th>
              <th style={{ width: 130, padding: '12px 6px', textAlign: 'right' }}>Kwota netto</th>
              <th style={{ width: 120, padding: '12px 6px', textAlign: 'right' }}>Kwota podatku</th>
              <th style={{ width: 120, padding: '12px 6px', textAlign: 'right' }}>Kwota brutto</th>
              <th style={{ width: 50, padding: '12px 6px' }}> &nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((row) => (
              <tr key={row['inv_id']}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Checkbox
                    size="sm"
                    checked={selected.includes(row['inv_id'])}
                    color={selected.includes(row['inv_id']) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(row['inv_id'])
                          : ids.filter((itemId) => itemId !== row['inv_id']),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>

                <td>
                  <Typography level="body-xs" textAlign={'center'}>{row['inv_nr']}</Typography>
                </td>
                <td>
                  <Typography level="body-xs" textAlign={'center'}>{String(row['date_create']).substring(0, 10)}</Typography>
                </td>
                <td>
                  <Typography level="body-xs" textAlign={'center'}>{String(row['date_sell']).substring(0, 10)}</Typography>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Avatar size="sm">{String(contractors.length > 0 ? (contractors.find(({ contr_id }) => contr_id === row['contr_id']))['name'] : '0').charAt(0)}</Avatar>
                    <div>
                      <Typography fontSize={10}>{contractors.length > 0 ? (contractors.find(({ contr_id }) => contr_id === row['contr_id']))['name'] : ''}</Typography>
                      <Typography fontSize={10}>{contractors.length > 0 ? (contractors.find(({ contr_id }) => contr_id === row['contr_id']))['nip'] : ''}</Typography>
                    </div>
                  </Box>
                </td>
                <td>
                  <Typography level="body-xs" textAlign={'right'}>{nrFormated(Number(row['sum_netto']))}</Typography>
                </td>
                <td>
                  <Typography level="body-xs" textAlign={'right'}>{nrFormated(Number(row['sum_tax']))}</Typography>
                </td>
                <td>
                  <Typography level="body-xs" textAlign={'right'}>{nrFormated(Number(row['sum_brutto']))}</Typography>
                </td>
                <td>
                  <IconButton onClick={() => getFile(row['pdf_name'])}>
                    <DownloadIcon sx={{ "&:hover": { color: "blue" }, fontSize: '16px' }} />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
      {/*<Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {['1', '2', '3', '…', '8', '9', '10'].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? 'outlined' : 'plain'}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box> */}
    </Box>
  );
}