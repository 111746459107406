import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import axios from "axios";

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';

import logo from './CCS_logo.png' ;


//import EditorToolbar from './EditorToolbar.tsx';
//import { renderMatches } from 'react-router-dom';

export default function MyProfile(props) {

  const comp_id = props.compID;

  //const [company, setCompany] = React.useState([]);
  const [cName, setCName] = React.useState<string>("");
  const [cPhone, setCPhone] = React.useState<string>("");
  const [cEmail, setCEmail] = React.useState<string>("");
  const [cNip, setCNip] = React.useState<string>("");
  const [cRegon, setCRegon] = React.useState<string>("");
  const [cAStreet, setCAStreet] = React.useState<string>("");
  const [cAZip, setCAZip] = React.useState<string>("");
  const [cACity, setCACity] = React.useState<string>("");
  const [cBank, setCBank] = React.useState<string>("");
  const [cAccount, setCAccount] = React.useState<string>("");
  const [cFile, setCFile] = React.useState<string>("mylogo.png");

  const [cLoaded, setCLoaded] = React.useState<boolean>(false);
  //const [picLoaded, setPicLoaded] = React.useState<boolean>(false);

  const [changed, setChanged] = React.useState<boolean>(false);


  //const [selectedImageForDisplay, setselectedImageForDisplay] = React.useState(null);

  const [binary, setBinary] = React.useState<any>();
  const [picLoaded, setPicLoaded] = React.useState<boolean>(false);
  
  function getFile(fName: string) {
    fetch('https://be.factoorian.pl/logos/' + fName)
      .then(response => {
        let output = response.blob();
        console.log(response);
        return output;
      })
      .then(data => {
        setBinary(data);
        setPicLoaded(true);
        console.log("Parsing file binary:", data);
      });
  }

  function getCompany() {
    fetch('https://be.factoorian.pl/companies/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
//        setCompany(JSON.parse(data));
        setCName((JSON.parse(data))[0]['name']);
        setCPhone((JSON.parse(data))[0]['phone']);
        setCEmail((JSON.parse(data))[0]['email']);
        setCNip((JSON.parse(data))[0]['nip']);
        setCRegon((JSON.parse(data))[0]['regon']);
        setCAStreet((JSON.parse(data))[0]['address_street']);
        setCAZip((JSON.parse(data))[0]['address_zip']);
        setCACity((JSON.parse(data))[0]['address_city']);
        setCBank((JSON.parse(data))[0]['bank']);
        setCAccount((JSON.parse(data))[0]['account']);
        setCFile((JSON.parse(data))[0]['logo_file']);
        setCLoaded(true);
        getFile((JSON.parse(data))[0]['logo_file']);

        console.log("Parsing", JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getCompany();
  }, []);

/*  React.useEffect(() => {
      getFile(cFile);
  }, []);
*/

  function updateCompany() {
    fetch(`https://be.factoorian.pl/companies/` + comp_id.toString(), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({cName, cPhone, cEmail, cNip, cRegon, cAStreet, cAZip, cACity, cBank, cAccount, cFile}),
    })
      .then(response => {
        return response.text();
      })
      .then(data => {
        alert(data);
 //       getMerchant();
      });
  }


  // ------------------ wgraj plik z logo na serwer -------------------
  async function handleUploadFile(file?: File) {
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("comp_id", comp_id);
    const res = await axios.post("https://be.factoorian.pl/uploadLogo", formData, {
      onUploadProgress: (progressEvent) => {
        console.log(
          "Upload progress: " +
            Math.round(
              (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
            ) +
            "%"
        );
      },
    });
    console.log(res);
    setCFile(res.data.filename);
    getFile(res.data.filename);
    setChanged(true);
  }

 

  const ref = React.useRef();
  
  const handleClick = (e) => {
    ref.current.click()
  }
// --------------------------------------------------------------------------------

  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Stack direction='row' spacing={1}>
            <HomeRoundedIcon color='primary' fontSize='small' />
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Moja firma
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Dane firmy</Typography>
            <Typography level="body-sm">
              Wprowadź podstawowe informacje o swojej firmie
            </Typography>
          </Box>
          <Divider />
            <Stack direction="column" spacing={1}>
              <AspectRatio
                ratio="1"
                variant='plain'
                maxHeight={200}
                objectFit='contain'
                sx={{ flex: 1 }}
              >
                <img
                  src={picLoaded?URL.createObjectURL(binary):logo}
                  alt="logo"
                />
              </AspectRatio>
              <IconButton
                aria-label="upload new picture"
                size="sm"
                variant="outlined"
                color="neutral"
                sx={{
                  bgcolor: 'background.body',
                  position: 'absolute',
                  zIndex: 2,
                  borderRadius: '50%',
                  left: 100,
                  top: 170,
                  boxShadow: 'sm',
                }}
                onClick={handleClick}
              >
                <EditRoundedIcon />
              </IconButton>
              <input 
               ref={ref}
               type='file'
               hidden accept=".jpg,.jpeg,.png"
               style={{ display: 'none' }}
               onChange={(e) =>
                handleUploadFile((e.target as HTMLInputElement).files?.[0])
               }
              />
            </Stack>
  
            <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >

            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>Nazwa firmy</FormLabel>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Input 
                   size="sm" 
                   placeholder="nazwa"
                   onChange={(e) => {
                    setCName(e.target.value)
                    setChanged(true)
                   }
                  }
                   value={cName==null?"":cName}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl>
                  <FormLabel>Telefon</FormLabel>
                  <Input
                    size="sm"
                    type="tel"
                    startDecorator={<ContactPhoneIcon />}
                    placeholder="telefon"
                    onChange={(e) => {
                      setCPhone(e.target.value)
                      setChanged(true)
                    }
                    }
                    value={cPhone==null?"":cPhone}
                  />
                </FormControl>
                <FormControl sx={{ flexGrow: 1 }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    size="sm"
                    type="email"
                    startDecorator={<EmailRoundedIcon />}
                    placeholder="email"
                    onChange={(e) => {
                      setCEmail(e.target.value)
                      setChanged(true)
                    }}
                    value={cEmail==null?"":cEmail}
                  //                    sx={{ flexGrow: 1 }}
                  />
                </FormControl>
              </Stack>

              <Divider />

              <Stack direction="row" spacing={2}>
                <FormControl>
                  <FormLabel>Numer Identyfikacji Podatkowej (NIP)</FormLabel>
                  <Input
                    size="sm"
                    type="number"
                    startDecorator={<CorporateFareRoundedIcon />}
                    placeholder="NIP"
                    onChange={(e) => {
                      setCNip(e.target.value)
                      setChanged(true)
                    }}
                    value={cNip==null?"":cNip}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>REGON</FormLabel>
                  <Input
                    size="sm"
                    type="number"
                    startDecorator={<CorporateFareRoundedIcon />}
                    placeholder="REGON"
                    onChange={(e) => {
                      setCRegon(e.target.value)
                      setChanged(true)
                    }}
                    value={cRegon==null?"":cRegon}
                  //                    sx={{ flexGrow: 1 }}
                  />
                </FormControl>
              </Stack>

              <Divider />

              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Typography level="title-md">Dane adresowe</Typography>
                <Stack spacing={1}>
                  <FormLabel>Ulica, numer budynku i lokalu</FormLabel>
                  <FormControl
                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  >
                    <Input
                      size="sm"
                      placeholder="adres"
                      startDecorator={<PlaceRoundedIcon />}
                      onChange={(e) => {
                        setCAStreet(e.target.value)
                        setChanged(true)
                      }}
                      value={cAStreet==null?"":cAStreet}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>Kod pocztowy</FormLabel>
                    <Input
                      size="sm"
                      type="tel"
                      startDecorator={<LocationCityRoundedIcon />}
                      placeholder="kod"
                      onChange={(e) => {
                        setCAZip(e.target.value)
                        setChanged(true)
                      }}
                      value={cAZip==null?"":cAZip}
                    />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Miasto</FormLabel>
                    <Input
                      size="sm"
                      type="email"
                      startDecorator={<LocationCityRoundedIcon />}
                      placeholder="miasto"
                      onChange={(e) => {
                        setCACity(e.target.value)
                        setChanged(true)
                      }}
                      value={cACity==null?"":cACity}
                    //                    sx={{ flexGrow: 1 }}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Divider />

              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Typography level="title-md">Dane rozliczeniowe</Typography>

                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel>Nazwa banku</FormLabel>
                    <Input
                      size="sm"
                      type="text"
                      startDecorator={<AccountBalanceRoundedIcon />}
                      placeholder="bank"
                      onChange={(e) => {
                        setCBank(e.target.value)
                        setChanged(true)
                      }}
                      value={cBank==null?"":cBank}
                    />
                  </FormControl>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel>Numer konta</FormLabel>
                    <Input
                      size="sm"
                      startDecorator={<AccountBalanceRoundedIcon />}
                      placeholder="numer"
                      onChange={(e) => {
                        setCAccount(e.target.value)
                        setChanged(true);
                      }}
                      value={cAccount==null?"":cAccount}
                    //                    sx={{ flexGrow: 1 }}
                    />
                  </FormControl>
                </Stack>
              </Stack>


            </Stack>
          </Stack>

          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <Button
               disabled={!changed}
               size="sm"
               variant="solid"
               onClick={() => {
                setChanged(false)
                updateCompany()
               }
               }
               >
                Zapisz zmiany
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>


      </Stack>
    </Box>
  );
}
