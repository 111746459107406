import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import Checkbox from '@mui/joy/Checkbox';
import Radio from '@mui/joy/Radio';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Table from '@mui/joy/Table';
import { RadioGroup } from '@mui/joy';
import Tooltip from '@mui/joy/Tooltip';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InfoIcon from '@mui/icons-material/Info';



export default function SettingsCompany(props) {
  const comp_id = props.compID;

  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  // ------------------ wczytujemy stawki podatkowe -----------
  const [settingsC, setSettingsC] = React.useState([]);
  const [taxes, setTaxes] = React.useState([]);
  const [autonr, setAutoNr] = React.useState(true);
  const [nextnr, setNextNr] = React.useState(1);
  const [autoFormat, setAutoFormat] = React.useState(1);
  const [autoCycle, setAutoCycle] = React.useState(1);

  const [chArray, setChArray] = React.useState<number[]>([]);
  const [defTax, setDefTax] = React.useState(1);


  function getTaxes() {
    fetch('https://be.factoorian.pl/taxes')
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setTaxes(JSON.parse(data));
        console.log(JSON.parse(data));
      });
  }


  React.useEffect(() => {
    getSettingsCompany();
    getTaxes();
  // eslint-disable-next-line
  }, []);

  function getSettingsCompany() {
    fetch('https://be.factoorian.pl/settings-company/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        const jsonData = JSON.parse(data)
        setSettingsC(jsonData);
        if (chArray.length > 0) {
          chArray.splice(0, chArray.length);
        }
        chArray.push(...jsonData[0]['tax_hide']);
        console.log("Settings data: ", jsonData);
        setDefTax(jsonData[0]['tax_default']);
        setAutoFormat(jsonData[0]['autoformat']);
        setAutoCycle(jsonData[0]['autocycle']);
        setAutoNr(jsonData[0]['autonumer']);
        setNextNr(jsonData[0]['nextnr']);
        setIsLoaded(true);
      });
  }

  function updateSettingsCompany() {
    fetch(`https://be.factoorian.pl/settings-company/` + comp_id.toString(), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({chArray, defTax, autonr, nextnr, autoFormat, autoCycle}),
    })
      .then(response => {
        return response.text();
      })
      .then(data => {
        alert(data);
      });
  }

  function handleCBEvent(id: number) {
    if (isDefault(id))
      return;
    const newArray: number[] = chArray.slice();
    let inx = newArray.indexOf(id);
    if (inx !== -1) {
      newArray.splice(inx, 1);
    }
    else {
      newArray.push(id);
    }
    console.log("Nowa tablica: ", newArray);
    setChArray(newArray);
    setChanged(true);
  }

  function isDefault(nr: number) {
    if (defTax === nr) {
      setOpen(true);
      return true;
    }
    else {
      return false;
    }
  }

  function isVisible(nr: number)  {
    if (chArray.indexOf(nr) > -1) {
      return true;
    }
    else {
      setOpen(true);
      return false;
    }
  }

  //----------------------------------------------------------------------------------    
  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      {isLoaded ?
        <>
          <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog variant='outlined' role='alertdialog'>
              <DialogTitle><WarningAmberRoundedIcon sx={{ color: 'darkred' }} /> <Typography textColor='darkred'>Nieprawidłowe ustawienie</Typography></DialogTitle>
              <Divider />
              <DialogContent><Typography textAlign='center'>Domyślna wartość musi być oznaczona jako pokazywana!</Typography></DialogContent>
              <Divider />
              <Stack spacing={2} direction='row' alignItems='center' justifyContent='center'>

                <Button
                  color='danger'
                  variant='soft'
                  onClick={() => {
                    setOpen(false);
                  }}>
                  Rozumiem
                </Button>

              </Stack>

            </ModalDialog>
          </Modal>

          <Box
            sx={{
              position: 'sticky',
              top: { sm: -100, md: -110 },
              bgcolor: 'background.body',
              //         zIndex: 9995,
            }}
          >
            <Box
              sx={{
                position: 'sticky',
                top: { sm: -100, md: -110 },
                bgcolor: 'background.body',
                zIndex: 9995,
              }}
            >
              <Box sx={{ px: { xs: 2, md: 6 } }}>
                <Stack direction='row' spacing={1}>
                  <HomeRoundedIcon color='primary' fontSize='small' />
                  <Typography color="primary" fontWeight={500} fontSize={12}>
                    Ustawienia
                  </Typography>

                </Stack>
              </Box>
            </Box>

            <Box sx={{ px: { xs: 2, md: 6 } }}>
              <Typography level="h3" component="h1" sx={{ mt: 1, mb: 2 }}>
                Ustawienia parametrów dla firmy
              </Typography>
            </Box>
          </Box>
          <Stack
            spacing={4}
            sx={{
              display: 'flex',
              //        maxWidth: '1200px',
              mx: 'auto',
              px: { xs: 2, md: 6 },
              py: { xs: 2, md: 3 },
            }}
          >
            <Card>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Checkbox
                 label='Automatyczna numeracja - następny numer faktury: '
                 checked={autonr}
                 onChange={(e)=>{
                  setAutoNr(e.target.checked);
                  setChanged(true);
                 }}
                />
                <Input 
                   sx={{ width: 70 }}
                   type='number'
                   size="sm"
                   disabled={!autonr}
                   onChange={(e) => {
                    setChanged(true);
                    setNextNr(e.target.valueAsNumber);
                   }
                  }
                    value={nextnr}
                  />
                  <Typography level="title-md"><Tooltip arrow size='sm' variant='solid' color='danger' title={<Box sx={{maxWidth: 300, textAlign: 'justify'}}><Typography>Wartość ustawiana automatycznie. Zmieniaj tylko w przypadkach awaryjnych, gdy naprawdę wiesz, co robisz! </Typography></Box>}><InfoIcon sx={{color: 'lightgrey', fontSize: 15}}/></Tooltip></Typography>
              </Stack>
              <Divider/>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Stack>
                  <WarningAmberRoundedIcon sx={{color: 'darkblue', fontSize: 50}}/>
                </Stack>
                <Stack width='95%'>
                <Typography level='body-sm' textColor='darkblue' sx={{textAlign: 'justify'}}>Zgodnie z przepisami faktury muszą mieć kolejne, nadawane chronologicznie, niepowtarzalne numery. Każda zmiana parametrów numeracji może spowodować, że któryś z tych warunków nie będzie spełniony, co może skutkować nałożeniem kary przez organy skarbowe. Pozostawienie automatycznej numeracji jest najbezpieczniejszym sposobem, ale możesz też numerować faktury ręcznie. System wciąż będzie dbał o unikalność numeracji, nie będzie jednak kontrolować ciągłości, czy chronologii.</Typography>
                </Stack>
              </Stack>
            </Card>

            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
            >

              <Card sx={{ width: '50%' }}>
                <Box sx={{ mb: 1 }}>
                  <Typography level="title-md">Format autonumeracji faktur &nbsp;<Tooltip arrow size='sm' variant='solid' title={<Box sx={{maxWidth: 300, textAlign: 'justify'}}><Typography sx={{textAlign: 'justify'}}>Sposób numeracji faktur przy wybraniu automatycznej numeracji. [nr] - kolejny, automatycznie nadawany numer faktury, [mies] - numer miesiąca w postaci dwóch cyfr, [rok] - numer roku w postaci czterech cyfr.</Typography></Box>}><InfoIcon sx={{color: 'lightgrey', fontSize: 15}}/></Tooltip></Typography>
                </Box>
                <Divider />
                <Stack spacing={2} direction='column'>
                  <RadioGroup name='rg1' defaultValue={1}>
                    <Radio
                     size='sm'
                     disabled={!autonr}
                     value={1}
                     checked={autoFormat===1}
                     label='nr/mies/rok'
                     onChange={() => {
                      setAutoFormat(1);
                      setChanged(true);
                    }}
                    />
                    <Radio
                     size='sm'
                     disabled={!autonr}
                     value={2}
                     checked={autoFormat===2}
                     label='rok/mies/nr'
                     onChange={() => {
                      setAutoFormat(2);
                      setChanged(true);
                    }}
                    />
                  </RadioGroup>
                </Stack>
              </Card>

              <Card sx={{ width: '50%' }}>
                <Box sx={{ mb: 1 }}>
                  <Typography level="title-md">Cykl autonumeracji faktur &nbsp;<Tooltip arrow size='sm' variant='solid' title={<Box sx={{maxWidth: 300, textAlign: 'justify'}}><Typography>Cykl, po jakim numer faktury będzie automatycznie resetowany do pozycji [1]. Wybranie trybu ciągłego oznacza, ze numeracja nigdy nie będzie resetowana. Nie zaleca się zmiany tego parametru po początkowym ustawieniu.</Typography></Box>}><InfoIcon sx={{color: 'lightgrey', fontSize: 15}}/></Tooltip></Typography>
                </Box>
                <Divider />
                <Stack spacing={2} direction='column'>
                  <RadioGroup name='rg2' defaultValue={1}>
                  <Radio
                     size='sm'
                     value={1}
                     disabled={!autonr}
                     checked={autoCycle===1}
                     label='miesięczny'
                     onChange={() => {
                      setAutoCycle(1);
                      setChanged(true);
                    }}
                    />
                    <Radio
                     size='sm'
                     value={2}
                     disabled={!autonr}
                     checked={autoCycle===2}
                     label='roczny'
                     onChange={() => {
                      setAutoCycle(2);
                      setChanged(true);
                     }}
                    />
                    <Radio
                     size='sm'
                     value={3}
                     disabled={!autonr}
                     checked={autoCycle===3}
                     label='ciągły'
                     onChange={() => {
                      setAutoCycle(3);
                      setChanged(true);
                     }}
                    />
                  </RadioGroup>
                </Stack>
              </Card>

            </Stack>



            <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">Stawki podatkowe VAT</Typography>
              </Box>
              <Divider />

              <Stack spacing={2} sx={{ my: 1 }}>

                <Box>
                  <RadioGroup >
                    <Table
                      aria-labelledby="tableTitle"
                      stickyHeader
                      hoverRow
                      sx={{
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                      }}>
                      <thead>
                        <tr>
                          <th style={{ width: '20%', textAlign: 'left', padding: '12px 6px' }}>Stawka podatkowa</th>
                          <th style={{ width: '60%', textAlign: 'left', padding: '12px 6px' }}>Opis</th>
                          <th style={{ width: '10%', textAlign: 'center', padding: '12px 6px' }}><Typography>Pokazuj &nbsp;<Tooltip arrow size='sm' variant='solid' title={<Box sx={{maxWidth: 300, textAlign: 'justify'}}><Typography>Zaznaczone stawki będą pokazywane do wyboru przy wystawianiu faktury, dla każdej pozycji niezależnie.</Typography></Box>}><InfoIcon sx={{color: 'lightgrey', fontSize: 15}}/></Tooltip></Typography></th>
                          <th style={{ width: '10%', textAlign: 'center', padding: '12px 6px' }}><Typography alignItems='center'>Domyślna &nbsp;<Tooltip arrow size='sm' variant='solid' title={<Box sx={{maxWidth: 300, textAlign: 'justify'}}><Typography>Stawka, która będzie domyślnie ustawiona dla towaru, podczas wystawiania faktury. Stawkę tą możesz wybrać niezależnie dla każdej pozycji faktury, spośród zaznaczonych w rubryce [Pokazuj]</Typography></Box>}><InfoIcon sx={{color: 'lightgrey', fontSize: 15}}/></Tooltip></Typography></th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxes.map((row) => (
                          <tr
                            key={row['tax_id']}
                          >
                            <td style={{ textAlign: 'left' }}><Typography level="body-xs">{row['tax_name']}</Typography></td>
                            <td style={{ textAlign: 'left' }}><Typography level="body-xs">{row['tax_comment']}</Typography></td>
                            <td style={{ textAlign: 'center' }}>
                              <Checkbox size='sm'
                                onChange={(e) => handleCBEvent(row['tax_id'])}
                                checked={chArray.indexOf(row['tax_id']) > -1}
                              />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <Radio
                                size='sm'
                                value={row['tax_id']}
                                onChange={() => {
                                  if (isVisible(row['tax_id']))
                                  {
                                    setDefTax(row['tax_id']);
                                    setChanged(true);
                                  }
                                }}
                                checked={defTax === row['tax_id']}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </RadioGroup>
                </Box>
              </Stack>
            </Card>
            <Stack alignItems='center'>
              <Button 
              disabled={!changed} 
              onClick={() => {
                console.log(chArray, defTax, settingsC, autonr, nextnr);
                updateSettingsCompany();
                setChanged(false);
              }}
              sx={{maxWidth: 170}}
              >
              Zachowaj
              </Button>
            </Stack>
          </Stack> </> : null}
    </Box>
  );
}