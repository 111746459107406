import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Table from '@mui/joy/Table';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import { IconButton } from '@mui/joy';


export default function LibGoods(props) {
  const comp_id = props.compID;

  const [open, setOpen] = React.useState<boolean>(false);
  const [goodName, setGoodName] = React.useState<string>('');
  const [goodPrice, setGoodPrice] = React.useState<number>(0);
  const [goodType, setGoodType] = React.useState<number | null>(1);

  const [xRow, setRow] = React.useState<number>(0);
  const [rowText, setRowText] = React.useState<string>('');


  // ------------------ wczytujemy towary z bazy -----------
  const [goods, setGoods] = React.useState([]);

  function getGoods() {
    fetch('https://be.factoorian.pl/library-goods/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setGoods(JSON.parse(data));
        console.log(JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getGoods();
  }, []);

  // ------------------ dodajemy towar do biblioteki -----------

  function addGood() {
    //    if (invValidate().errors > 0) {
    //      setOpenError(true);
    //      return;
    //    }

    let xbody = JSON.stringify({ goodName, goodPrice, goodType });
    console.log(xbody);
    fetch(`https://be.factoorian.pl/library-goods/` + comp_id.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ goodName, goodPrice, goodType }),
    })
      .then(response => {
        let output = response.text();
        console.log(output);
        resetInput();
        return output;
      })
      .then(data => {
         console.log(JSON.parse(data));
        getGoods();
      });
  }

  // ------------------ usuwamy towar z biblioteki -----------
  function removeGood(id: number) {
    //    if (invValidate().errors > 0) {
    //      setOpenError(true);
    //      return;
    //    }


    let xbody = JSON.stringify({ id });
    console.log(xbody);
    fetch(`https://be.factoorian.pl/library-goods/` + comp_id.toString(), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then(response => {
        let output = response.text();
        console.log(output);
        resetInput();
        return output;
      })
      .then(data => {
        console.log(JSON.parse(data));
        getGoods();
      });
  }

  function resetInput() {
    setGoodName('');
    setGoodPrice(0);
    setGoodType(1);
  }

  //----------------------------------------------------------------------------------    
  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant='outlined' role='alertdialog'>
          <DialogTitle><WarningAmberRoundedIcon sx={{ color: 'darkred' }}/> <Typography textColor='darkred'> Usuwasz pozycję!</Typography></DialogTitle>
          <Divider/>
          <DialogContent><Typography textAlign='center' textColor='grey'>Czy na pewno chcesz usunąć towar</Typography><Typography textAlign='center'>{rowText} ?</Typography></DialogContent>
          <Divider/>
            <Stack spacing={2} direction='row' alignItems='center' justifyContent='center'>

              <Button
                color='danger'
                variant='soft'
                onClick={() => {
                  setOpen(false);
                }}>
                Nie
              </Button>

              <Button 
                color='danger'
                onClick={() => {
                  removeGood(xRow);
                  setOpen(false);
                }}>
                Tak
              </Button>

            </Stack>

        </ModalDialog>
      </Modal>



      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          //         zIndex: 9995,
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Stack direction='row' spacing={1}>
              <HomeRoundedIcon color='primary' fontSize='small' />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Biblioteki
              </Typography>
              <ChevronRightRoundedIcon color='primary' fontSize="small" />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Towary i usługi
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h3" component="h1" sx={{ mt: 1, mb: 2 }}>
            Lista towarów i usług
          </Typography>
        </Box>
      </Box>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          //        maxWidth: '1200px',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >

        <Card>
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >
            <Stack spacing={2} direction='column' width={'100%'}>

              <Stack direction='row' spacing={4}>

                <Stack direction='column' spacing={1} width={'10%'}>
                  <Typography level="body-sm">
                    Rodzaj
                  </Typography>
                  <Select
                    size="sm"
                    value={goodType}
                    onChange={(e, newValue) => setGoodType(newValue)}
                  >
                    <Option value={1}>
                      <LocalGroceryStoreRoundedIcon /> towar
                    </Option>
                    <Option value={2}>
                      <EmojiPeopleRoundedIcon /> usługa
                    </Option>
                  </Select>

                </Stack>

                <Stack direction='column' spacing={1} width={'80%'}>
                  <Typography level="body-sm">
                    Nazwa
                  </Typography>
                  <Input
                    value={goodName}
                    required
                    onChange={(e) => setGoodName(e.target.value)}
                  />
                </Stack>

                <Stack direction='column' spacing={1} width={'10%'}>
                  <Typography level="body-sm">
                    Cena jedn.
                  </Typography>
                  <Input
                    type='number'
                    value={goodPrice}
                    required
                    onChange={(e) => setGoodPrice(e.target.valueAsNumber)}
                  />
                </Stack>

              </Stack>
            </Stack>
          </Stack>

          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <Button size="sm" variant="solid" onClick={() => addGood()} disabled={goodName === ''}>
                Dodaj towar
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>


        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Zestawienie</Typography>
          </Box>
          <Divider />

          <Stack spacing={2} sx={{ my: 1 }}>

            <Box>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                  '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                  '--Table-headerUnderlineThickness': '1px',
                  '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                  '--TableCell-paddingY': '4px',
                  '--TableCell-paddingX': '8px',
                }}>
                <thead>
                  <tr>
                    <th style={{ width: '5%', textAlign: 'center', padding: '12px 6px' }}>&nbsp;</th>
                    <th style={{ width: '80%', textAlign: 'left', padding: '12px 6px' }}>Nazwa towaru / usługi</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px', whiteSpace: 'normal' }}>Cena netto</th>
                    <th style={{ width: '5%', textAlign: 'center', padding: '12px 6px' }}>&nbsp;</th>

                  </tr>
                </thead>
                <tbody>
                  {goods.map((row) => (
                    <tr
                      key={row['lib_id']}
                    >
                      <td style={{ textAlign: 'center' }}>{row['value_bool'] ? <LocalGroceryStoreRoundedIcon sx={{ fontSize: '18px', color: 'darkblue' }} /> : <EmojiPeopleRoundedIcon sx={{ fontSize: '18px', color: 'green' }} />}</td>
                      <td style={{ textAlign: 'left' }}><Typography level="body-xs">{row['value_char']}</Typography></td>
                      <td style={{ textAlign: 'right' }}><Typography level="body-xs">{Number(row['value_nr']).toFixed(2)}</Typography></td>
                      <td style={{ textAlign: 'center' }}>
                        <IconButton onClick={
                          () => {
                            setRow(row['lib_id']);
                            setRowText(row['value_char'])
                            setOpen(true);
                          }
                        }>
                          <HighlightOffRoundedIcon sx={{ "&:hover": { color: "red" }, fontSize: '18px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
}