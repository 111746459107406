import { Page, Text, View, Document, Image, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';

import flogo from './CCS_logo.png';

export const Quixote = () => (
  <Document>
    <Page style={pdfStyles.body}>

      <View style={pdfStyles.viewRow}>
      	<View style={pdfStyles.viewHalf}>
          <Image
        	style={pdfStyles.image}
        	src={flogo}
      	  />
        </View>
      	<View style={pdfStyles.viewHalf}>
      		<Text style={pdfStyles.paymentGrey}>Data wystawienia: <Text style={pdfStyles.payment}>30.04.2023 r.</Text></Text>    
      		<Text style={pdfStyles.paymentGrey}>Data sprzedaży: <Text style={pdfStyles.payment}>30.04.2024 r.</Text></Text>       
        </View>
      </View>
      
      <Text style={pdfStyles.title}>Faktura nr 34/11/2024</Text>
      
      <View style={pdfStyles.viewRow}>
      	<View style={pdfStyles.viewHalfB}>
          <View style={pdfStyles.viewColumn}>
            <View style={pdfStyles.viewSBHead}>
              <Text style={pdfStyles.subtitle}>
                Sprzedawca
      		  </Text>   
            </View>
            <View style={pdfStyles.viewDane}>
              <Text style={pdfStyles.compGrey}>Nazwa: <Text style={pdfStyles.compBlack}>CORNELL Centrum Szkoleń Agnieszka Dobrzyńska</Text></Text> 
              <Text style={pdfStyles.compGrey}>Adres: <Text style={pdfStyles.compBlack}>02-554 Warszawa, al. Niepodległości 148/19</Text></Text> 
              <Text style={pdfStyles.compGrey}>NIP: <Text style={pdfStyles.compBlack}>6691239065</Text></Text> 
              <Text style={pdfStyles.compGrey}>REGON: <Text style={pdfStyles.compBlack}>142188545</Text></Text>
              <Text style={pdfStyles.compGrey}>Telefon: <Text style={pdfStyles.compBlue}>668 837 747</Text></Text> 
              <Text style={pdfStyles.compGrey}>E-mail: <Text style={pdfStyles.compBlue}>dobrzynska@ccs.edu.pl</Text></Text>
              <Text style={pdfStyles.compGrey}>Strona WWW: <Text style={pdfStyles.compBlue}>ccs.edu.pl</Text></Text>      		  
            
            </View>
          </View>
        </View>
      	<View style={pdfStyles.viewHalfB}>
          <View style={pdfStyles.viewColumn}>
            <View style={pdfStyles.viewSBHead}>
              <Text style={pdfStyles.subtitle}>
                Nabywca
      		  </Text>   
            </View>
            <View style={pdfStyles.viewDane}>
              <Text style={pdfStyles.compGrey}>Nazwa: <Text style={pdfStyles.compBlack}>Analizy & Doradztwo Wojciech Pietr</Text></Text> 
              <Text style={pdfStyles.compGrey}>Adres: <Text style={pdfStyles.compBlack}>02-554 Warszawa, al. Niepodległości 148/19</Text></Text> 
              <Text style={pdfStyles.compGrey}>NIP: <Text style={pdfStyles.compBlack}>8951564887</Text></Text> 
              <Text style={pdfStyles.compGrey}>REGON: <Text style={pdfStyles.compBlack}>528176463</Text> 
      		  </Text> 
            
            </View>
          </View>        
        </View>
      </View>
       
      <Text style={pdfStyles.paymentGrey}>Termin płatności: <Text style={pdfStyles.payment}>21 dni</Text></Text>
      <Text style={pdfStyles.paymentGrey}>Forma płatności: <Text style={pdfStyles.payment}>przelew</Text></Text>
      <Text style={pdfStyles.paymentGrey}>Płatność podzielona: <Text style={pdfStyles.payment}>NIE</Text></Text>
      <Text style={pdfStyles.paymentGrey}><Text style={pdfStyles.payment}>mBank 86 2000 3000 5000 4000</Text></Text>
      <View style={pdfStyles.viewEmpty10}></View>

      <View style={pdfStyles.viewRow}>
      	<Text style={pdfStyles.tabTowarH}>Nazwa towaru</Text>
      	<Text style={pdfStyles.tabCenaH}>Cena netto</Text>
        <Text style={pdfStyles.tabIloscH}>Ilość</Text>
        <Text style={pdfStyles.tabWartoscH}>Razem</Text>
      </View>
      <View style={pdfStyles.viewRow}>
      	<Text style={pdfStyles.tabTowar}>Indywidualna lekcja języka angielskiego</Text>
      	<Text style={pdfStyles.tabCena}>85,00</Text>
        <Text style={pdfStyles.tabIlosc}>1 gl</Text>
        <Text style={pdfStyles.tabWartosc}>85,00</Text>
      </View>
      <View style={pdfStyles.viewRow}>
      	<Text style={pdfStyles.tabTowar}>Indywidualna lekcja języka niemckiego</Text>
      	<Text style={pdfStyles.tabCena}>100,00</Text>
        <Text style={pdfStyles.tabIlosc}>3 gl</Text>
        <Text style={pdfStyles.tabWartosc}>300,00</Text>
      </View>
      
      <View style={pdfStyles.viewEmpty10}></View>
      
      <View style={pdfStyles.viewRow}>
      	<Text style={pdfStyles.tab40empty}></Text>
      	<Text style={pdfStyles.tab40}>Do zapłaty, w tym:</Text>
        <Text style={pdfStyles.tab20}>385,00</Text>
      </View> 
      <View style={pdfStyles.viewRow}>
      	<Text style={pdfStyles.tab40empty}></Text>
      	<Text style={pdfStyles.tab40next}> - kwota netto</Text>
        <Text style={pdfStyles.tab20next}>385,00</Text>
      </View> 
      <View style={pdfStyles.viewRow}>
      	<Text style={pdfStyles.tab40empty}></Text>
      	<Text style={pdfStyles.tab40next}>- VAT</Text>
        <Text style={pdfStyles.tab20next}>0,00</Text>
      </View> 
      
      <View style={pdfStyles.viewEmpty30}></View>     
	  
      <View style={pdfStyles.viewRow}>
        <View style={pdfStyles.viewHalf}></View>
        <View style={pdfStyles.viewHalf}>
        	<Text style={pdfStyles.signGrey}>Osoba uprawniona do podpisu</Text>
        	<Text style={pdfStyles.sign}>Agnieszka Dobrzyńska</Text>
        </View>
      </View>
    
    
          <Text style={pdfStyles.pageNumber} fixed>Wygenerowano z systemu FACTOORIAN</Text>
    </Page>
  </Document>
);

Font.register({
  family: 'quicksand',
  src: 'https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18G0wx40QDw.ttf'
});

export const pdfStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'quicksand'
  },
  
  viewRow: {
    flexDirection: 'row'
  },
  
  viewColumn: {
    flexDirection: 'column'
  },
  
  viewSBHead: {
    backgroundColor: '#f0f0f0',
  },
  
  viewDane: {
    margin: 10
  },
  
  viewHalf: {
    width: '50%',
	margin: 3
  },

  viewHalfB: {
    width: '50%',
    border: 1,
    borderColor: 'grey',
	margin: 3
  },
  
  viewEmpty10: {
    height: 10
  },
  
  viewEmpty30: {
    height: 30
  },

  tabLpH: {
  	width: '5%',
    fontSize: 9,
    padding: 3,
    border: 1,
    backgroundColor: '#f0f0f0',
    textAlign: 'right'
  },
  
  tabTowarH: {
  	width: '45%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    backgroundColor: '#f0f0f0'
  },
  
  tabCenaH: {
  	width: '15%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    textAlign: 'right',
    backgroundColor: '#f0f0f0'
  },
  
  tabIloscH: {
  	width: '10%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    textAlign: 'center',
    backgroundColor: '#f0f0f0'
  },
  
  tabVatH: {
  	width: '10%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    textAlign: 'center',
    backgroundColor: '#f0f0f0'
  },
  
  tabWartoscH: {
  	width: '15%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    textAlign: 'right',
    backgroundColor: '#f0f0f0'
  },
  
  tabLp: {
  	width: '5%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderTop: 0,
    textAlign: 'right'
  },
  
  tabTowar: {
  	width: '45%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    borderTop: 0
  },
  
  tabCena: {
  	width: '15%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    borderTop: 0,
    textAlign: 'right'
  },
  
  tabIlosc: {
  	width: '10%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    borderTop: 0,
    textAlign: 'center'
  },
  
  tabVat: {
  	width: '10%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    borderTop: 0,
    textAlign: 'center'
  },

  tabWartosc: {
  	width: '15%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    borderTop: 0,
    textAlign: 'right'
  },
  
  tab50empty: {
  	width: '50%',
    fontSize: 10,
    padding: 3,
  },
  
  tab35: {
  	width: '35%',
    fontSize: 9,
    padding: 3,
    border: 1,
  },
  
  tab15: {
  	width: '15%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    textAlign: 'right'
  },
  
  tab35next: {
  	width: '35%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderTop: 0,
    color: 'grey'
  },
  
  tab15next: {
  	width: '15%',
    fontSize: 9,
    padding: 3,
    border: 1,
    borderLeft: 0,
    borderTop: 0,
    textAlign: 'right',
    color: 'grey'
  },

  title: {
    fontSize: 16,
    textAlign: 'center',
	margin: 15
  },

  payment: {
    color: 'black',
    fontSize: 9,
    textAlign: 'left',
  },
  paymentGrey: {
    color: 'grey',
    fontSize: 9,
    textAlign: 'left',
    marginRight: 10
  },
  
  dates: {
    color: 'black',
    fontSize: 10,
    textAlign: 'right',
  },
  datesGrey: {
    color: 'grey',
    fontSize: 10,
    textAlign: 'right',
    marginRight: 10
  },

  comment: {
    color: 'black',
    fontSize: 8,
    textAlign: 'left',
  },
  commentGrey: {
    color: 'grey',
    fontSize: 8,
    textAlign: 'left',
    marginRight: 10
  },

  signGrey: {
    color: 'grey',
    fontSize: 9,
    textAlign: 'center',
  },
  sign: {
    color: 'black',
    fontSize: 9,
    textAlign: 'center',
  },
  
  subtitle: {
    fontSize: 12,
    margin: 6,
	textAlign: 'center'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    objectFit: 'contain',
    maxHeight: 80
  },
  header: {
    fontSize: 12,
	marginBottom: 2,
    textAlign: 'center',
    color: 'grey',
  },
  date: {
    fontSize: 13,
    textAlign: 'center',
    marginBottom: 10
  },
  compGrey: {
    color: 'grey',
    fontSize: 9
  },
  compBlack: {
    color: 'black',
    fontSize: 9
  },  
  compLBlue: {
    color: '#0000FF',
    fontSize: 9
  },
  compBlue: {
    color: 'darkblue',
    fontSize: 9
  },  
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#BBBBBB',
  },
});

//ReactPDF.render(<Quixote />);
