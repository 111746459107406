import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import CheckBox from '@mui/joy/Checkbox';
import Stack from '@mui/joy/Stack';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Add from '@mui/icons-material/Add';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Tooltip from '@mui/joy/Tooltip';
import CircularProgress from '@mui/joy/CircularProgress';
import axios from "axios";

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';

//import Table from '@mui/material/Table';
import Table from '@mui/joy/Table';


import { Page, Text, View, Document, Image, PDFDownloadLink, Font, PDFViewer, BlobProvider } from '@react-pdf/renderer';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import BusinessIcon from '@mui/icons-material/Business';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InfoIcon from '@mui/icons-material/Info';



import { pdfStyles } from './fakturaPDF.js';
import flogo from './mylogo.png';

Font.register({
  family: 'quicksand',
  src: 'https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18G0wx40QDw.ttf'
});


function createData(
  tableKey: number,
  goodId: number | null,
  goodName: string,
  nettoOne: number,
  taxId: number | null,
  taxValue: number,
  taxString: string,
  count: number,
  unitId: number | null,
  unitShortName: string,
  unitFullName: string,
  nettoAll: number,
  bruttoAll: number
) {
  return { tableKey, goodId, goodName, nettoOne, taxId, taxValue, taxString, count, unitId, unitShortName, unitFullName, nettoAll, bruttoAll };
}

const rows: any = [];

export default function NewInvoice(props) {
  const comp_id = props.compID;

  const [open, setOpen] = React.useState<boolean>(false);
  const [open2, setOpen2] = React.useState<boolean>(false);
  const [openError, setOpenError] = React.useState<boolean>(false)
  const [openErrorPos, setOpenErrorPos] = React.useState<boolean>(false)

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const [goodValue, setGoodValue] = React.useState<number>(0);
  const [goodCount, setGoodCount] = React.useState<number>(1);
  // eslint-disable-next-line
  const [goodTax, setGoodTax] = React.useState<number | null>(0);
  const [goodId, setGoodId] = React.useState<number | null>(null);
  const [unitId, setUnitId] = React.useState<number | null>(null);
  const [taxId, setTaxId] = React.useState<number | null>(null);
  const [contrId, setContrId] = React.useState<number | null>(null);
  const [paymentForm, setPaymentForm] = React.useState<number | null>(1);
  const [paymentDiv, setPaymentDiv] = React.useState<number | null>(1);
  const [showBank, setShowBank] = React.useState<boolean>(true);
  const [comment, setComment] = React.useState<string>("");
  const [commentOn, setCommentOn] = React.useState<boolean>(false);

  const [invNumber, setInvNumber] = React.useState<string>("");
  const [invDate, setInvDate] = React.useState<string>((new Date()).toISOString().substring(0, 10));
  const [sellDate, setSellDate] = React.useState<string>((new Date()).toISOString().substring(0, 10));
  const [payDays, setPayDays] = React.useState<number>(0);

  const [tKey, setTKey] = React.useState<number>(0);

  const [binary, setBinary] = React.useState<any>();
  const [picLoaded, setPicLoaded] = React.useState<boolean>(false);
  const [cLoaded, setCLoaded] = React.useState<boolean>(false);

  const [invUploaded, setInvUploaded] = React.useState<boolean>(false);

  let invUp = false;
  let invFile;

  function getFile(fName: string) {
    fetch('https://be.factoorian.pl/logos/' + fName)
      .then(response => {
        let output = response.blob();
        console.log("Pyt:", fName, cFile);
        console.log("Odp:", response);
        return output;
      })
      .then(data => {
        setBinary(data);
        setPicLoaded(true);
        console.log("Parsing file binary:", data);
      });
  }

  function resetModal() {
    setGoodValue(0);
    setGoodCount(1);
    setGoodTax(null);
    setGoodId(null);
    setUnitId(null);
  }

  // ------------------ wstawianie informacji o firmie do faktury PDF -----------
  function getSums() {
    let sumaNetto = 0;
    let sumaBrutto = 0;
    let sumaPodatek = 0;

    // eslint-disable-next-line
    rows.map((row) => {
      sumaNetto += row.nettoAll;
      sumaBrutto += row.bruttoAll;
      sumaPodatek += row.nettoAll * row.taxValue / 100;
    });

    return { sumaBrutto, sumaPodatek, sumaNetto };
  }

  function getTable() {
    let lp = 1;
    const tabContent = rows.map((row) =>
      <View style={pdfStyles.viewRow}>
        <Text style={pdfStyles.tabLp}>{lp++}</Text>
        <Text style={pdfStyles.tabTowar}>{(goods.find(({ lib_id }) => lib_id === row.goodId))['value_char']}</Text>
        <Text style={pdfStyles.tabCena}>{(row.nettoOne).toFixed(2)}</Text>
        <Text style={pdfStyles.tabIlosc}>{row.count} {(units.find(({ lib_id }) => lib_id === row.unitId))['value_char']}</Text>
        <Text style={pdfStyles.tabVat}>{(taxes.find(({ tax_id }) => tax_id === row.taxId))['tax_name']}</Text>
        <Text style={pdfStyles.tabWartosc}>{(row.bruttoAll).toFixed(2)}</Text>
      </View>
    );

    return tabContent;
  }

  function getSummary() {
    let sumaNetto = 0;
    let sumaBrutto = 0;
    let sumaPodatek = 0;

    // eslint-disable-next-line
    rows.map((row) => {
      sumaNetto += row.nettoAll;
      sumaBrutto += row.bruttoAll;
      sumaPodatek += row.nettoAll * row.taxValue / 100;
    });

    const tabSummary = (
      <View>
        <View style={pdfStyles.viewRow}>
          <Text style={pdfStyles.tab50empty}></Text>
          <Text style={pdfStyles.tab35}>Do zapłaty, w tym:</Text>
          <Text style={pdfStyles.tab15}>{sumaBrutto.toFixed(2)}</Text>
        </View>
        <View style={pdfStyles.viewRow}>
          <Text style={pdfStyles.tab50empty}></Text>
          <Text style={pdfStyles.tab35next}> - kwota netto</Text>
          <Text style={pdfStyles.tab15next}>{sumaNetto.toFixed(2)}</Text>
        </View>
        <View style={pdfStyles.viewRow}>
          <Text style={pdfStyles.tab50empty}></Text>
          <Text style={pdfStyles.tab35next}>- VAT</Text>
          <Text style={pdfStyles.tab15next}>{sumaPodatek.toFixed(2)}</Text>
        </View>
      </View>
    );

    return tabSummary;
  }

  const putCompany = () => (
    <View style={pdfStyles.viewDane}>
      <Text style={pdfStyles.compGrey}>Nazwa: <Text style={pdfStyles.compBlack}>{cName}</Text></Text>
      <Text style={pdfStyles.compGrey}>Adres: <Text style={pdfStyles.compBlack}>{cAZip + ", " + cACity + ", " + cAStreet}</Text></Text>
      <Text style={pdfStyles.compGrey}>NIP: <Text style={pdfStyles.compBlack}>{cNip}</Text></Text>
      <Text style={pdfStyles.compGrey}>REGON: <Text style={pdfStyles.compBlack}>{cRegon}</Text></Text>
      <Text style={pdfStyles.compGrey}>Telefon: <Text style={pdfStyles.compBlue}>{cPhone}</Text></Text>
      <Text style={pdfStyles.compGrey}>E-mail: <Text style={pdfStyles.compBlue}>{cEmail}</Text></Text>
      <Text style={pdfStyles.compGrey}>Strona WWW: <Text style={pdfStyles.compBlue}>- ccs.edu.pl -</Text></Text>
    </View>
  );

  const putContractor = () => (
    <View style={pdfStyles.viewDane}>
      <Text style={pdfStyles.compGrey}>Nazwa: <Text style={pdfStyles.compBlack}>{(contractors.find(({ contr_id }) => contr_id === contrId))['name']}</Text></Text>
      <Text style={pdfStyles.compGrey}>Adres: <Text style={pdfStyles.compBlack}>{(contractors.find(({ contr_id }) => contr_id === contrId))['address_zip'] + ' ' + (contractors.find(({ contr_id }) => contr_id === contrId))['address_city'] + ', ' + (contractors.find(({ contr_id }) => contr_id === contrId))['address_street']}</Text></Text>
      <Text style={pdfStyles.compGrey}>NIP: <Text style={pdfStyles.compBlack}>{(contractors.find(({ contr_id }) => contr_id === contrId))['nip']}</Text></Text>
      <Text style={pdfStyles.compGrey}>REGON: <Text style={pdfStyles.compBlack}>{(contractors.find(({ contr_id }) => contr_id === contrId))['regon']}</Text></Text>
    </View>
  );

  const putpayInfo = () => (
    <View>
      <Text style={pdfStyles.paymentGrey}>Termin płatności: <Text style={pdfStyles.payment}>{payDays} dni</Text></Text>
      <Text style={pdfStyles.paymentGrey}>Forma płatności: <Text style={pdfStyles.payment}>{paymentForm === 1 ? 'przelew' : 'gotówka'}</Text></Text>
      <Text style={pdfStyles.paymentGrey}>Płatność podzielona: <Text style={pdfStyles.payment}>{paymentDiv === 1 ? 'NIE' : 'TAK'}</Text></Text>
      {showBank ? (<Text style={pdfStyles.paymentGrey}>Konto bankowe: <Text style={pdfStyles.payment}>{cAccount} ({cBank})</Text></Text>) : ''}
    </View>
  );

  const putComment = () => (
    <View>
      {commentOn ? (<View><View style={pdfStyles.viewEmpty10}></View><Text style={pdfStyles.commentGrey}>Uwagi: <Text style={pdfStyles.comment}>{comment}</Text></Text><View style={pdfStyles.viewEmpty10}></View><View style={pdfStyles.viewEmpty10}></View></View>) : (<View style={pdfStyles.viewEmpty30}></View>)}
    </View>
  );

  // ------------------ wczytujemy informacje o firmie ------------
  // eslint-disable-next-line
  const [company, setCompany] = React.useState([]);
  const [cName, setCName] = React.useState<string>("");
  const [cPhone, setCPhone] = React.useState<string>("");
  const [cEmail, setCEmail] = React.useState<string>("");
  const [cNip, setCNip] = React.useState<string>("");
  const [cRegon, setCRegon] = React.useState<string>("");
  const [cAStreet, setCAStreet] = React.useState<string>("");
  const [cAZip, setCAZip] = React.useState<string>("");
  const [cACity, setCACity] = React.useState<string>("");
  const [cBank, setCBank] = React.useState<string>("");
  const [cAccount, setCAccount] = React.useState<string>("");
  const [cFile, setCFile] = React.useState<string>("mylogo.png");

  function getCompany() {
    fetch('https://be.factoorian.pl/companies/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setCompany(JSON.parse(data));
        setCName((JSON.parse(data))[0]['name']);
        setCPhone((JSON.parse(data))[0]['phone']);
        setCEmail((JSON.parse(data))[0]['email']);
        setCNip((JSON.parse(data))[0]['nip']);
        setCRegon((JSON.parse(data))[0]['regon']);
        setCAStreet((JSON.parse(data))[0]['address_street']);
        setCAZip((JSON.parse(data))[0]['address_zip']);
        setCACity((JSON.parse(data))[0]['address_city']);
        setCBank((JSON.parse(data))[0]['bank']);
        setCAccount((JSON.parse(data))[0]['account']);
        setCFile((JSON.parse(data))[0]['logo_file']);
        setCLoaded(true);
        getFile((JSON.parse(data))[0]['logo_file']);

        console.log("Parsing", JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getCompany();
  }, []);


  // ----------------- Create Document Component
  const Quixote = () => (
    <Document>
      <Page style={pdfStyles.body}>

        <View style={pdfStyles.viewRow}>
          <View style={pdfStyles.viewHalf}>
            <Image
              style={pdfStyles.image}
              src={picLoaded ? URL.createObjectURL(binary) : flogo}
            />
          </View>
          <View style={pdfStyles.viewHalf}>
            <Text style={pdfStyles.datesGrey}>Data wystawienia: <Text style={pdfStyles.dates}>{invDate}</Text></Text>
            <Text style={pdfStyles.datesGrey}>Data sprzedaży: <Text style={pdfStyles.dates}>{sellDate}</Text></Text>
          </View>
        </View>

        <Text style={pdfStyles.title}>Faktura nr {invNumber}</Text>

        <View style={pdfStyles.viewRow}>
          <View style={pdfStyles.viewHalfB}>
            <View style={pdfStyles.viewColumn}>
              <View style={pdfStyles.viewSBHead}>
                <Text style={pdfStyles.subtitle}>
                  Sprzedawca
                </Text>
              </View>

              {putCompany()}

            </View>
          </View>
          <View style={pdfStyles.viewHalfB}>
            <View style={pdfStyles.viewColumn}>
              <View style={pdfStyles.viewSBHead}>
                <Text style={pdfStyles.subtitle}>
                  Nabywca
                </Text>
              </View>

              {putContractor()}

            </View>
          </View>
        </View>

        <View style={pdfStyles.viewEmpty10}></View>

        {putpayInfo()}

        <View style={pdfStyles.viewEmpty10}></View>

        <View style={pdfStyles.viewRow}>
          <Text style={pdfStyles.tabLpH}>Lp.</Text>
          <Text style={pdfStyles.tabTowarH}>Nazwa</Text>
          <Text style={pdfStyles.tabCenaH}>Cena netto</Text>
          <Text style={pdfStyles.tabIloscH}>Ilość</Text>
          <Text style={pdfStyles.tabVatH}>VAT %</Text>
          <Text style={pdfStyles.tabWartoscH}>Razem</Text>
        </View>

        {getTable()}

        <View style={pdfStyles.viewEmpty10}></View>

        {getSummary()}

        {putComment()}

        <View style={pdfStyles.viewRow}>
          <View style={pdfStyles.viewHalf}></View>
          <View style={pdfStyles.viewHalf}>
            <Text style={pdfStyles.signGrey}>Osoba uprawniona do podpisu</Text>
            <Text style={pdfStyles.sign}>&nbsp;</Text>
          </View>
        </View>

        <Text style={pdfStyles.pageNumber} fixed>Wygenerowano z systemu FACTOORIAN</Text>
      </Page>
    </Document>
  );

  function posValidate() {
    let errors = 0;
    let warnings = 0;
    let warnPrice = false;
    let errUnit = false;
    let errGood = false;


    if (goodId === null) {
      errGood = true;
      errors++;
    }

    if (unitId === null) {
      errUnit = true;
      errors++;
    }

    if (goodValue === 0) {
      warnPrice = true;
      warnings++;
    }

    const errList = () => (
      <List aria-labelledby="decorated-list">
        {errGood ? (<ListItem><ListItemDecorator><ErrorOutlineRoundedIcon style={{ color: 'red' }} /></ListItemDecorator> Nie wybrano towaru/usługi.</ListItem>) : ''}
        {errUnit ? (<ListItem><ListItemDecorator><ErrorOutlineRoundedIcon style={{ color: 'red' }} /></ListItemDecorator> Nie wybrano jednostki miary.</ListItem>) : ''}
        {warnPrice ? (<ListItem><ListItemDecorator><WarningAmberRoundedIcon style={{ color: 'orange' }} /></ListItemDecorator> Cena jednostkowa wynosi 0.</ListItem>) : ''}
      </List>
    );

    return { errors, warnings, errList };
  };

  function invValidate() {
    let errors = 0;
    let warnings = 0;
    let errInvNr = false;
    let errContr = false;
    let errGoods = false;
    let warnAccountA = false;
    let warnAccountB = false;
    let warnComment = false;

    if (invNumber === "") {
      errInvNr = true;
      errors++;
    }

    if (contrId === null) {
      errContr = true;
      errors++;
    }

    if (rows.length === 0) {
      errGoods = true;
      errors++;
    }

    if (showBank && (paymentForm !== 1)) {
      warnAccountA = true;
      warnings++;
    }

    if (!showBank && (paymentForm === 1)) {
      warnAccountB = true;
      warnings++;
    }

    if (commentOn && (comment === '')) {
      warnComment = true;
      warnings++;
    }

    const errList = () => (
      <List aria-labelledby="decorated-list">
        {errInvNr ? (<ListItem><ListItemDecorator><ErrorOutlineRoundedIcon style={{ color: 'red' }} /></ListItemDecorator> Faktura musi mieć nadany numer.</ListItem>) : ''}
        {errContr ? (<ListItem><ListItemDecorator><ErrorOutlineRoundedIcon style={{ color: 'red' }} /></ListItemDecorator> Nie wybrano kontrahenta.</ListItem>) : ''}
        {errGoods ? (<ListItem><ListItemDecorator><ErrorOutlineRoundedIcon style={{ color: 'red' }} /></ListItemDecorator> Musisz dodać przynajmniej jedną pozycję do faktury.</ListItem>) : ''}

        {warnAccountA ? (<ListItem><ListItemDecorator><WarningAmberRoundedIcon style={{ color: 'orange' }} /></ListItemDecorator> Wybrano płatność gotówką, ale pozostawiono numer konta na fakturze.</ListItem>) : ''}
        {warnAccountB ? (<ListItem><ListItemDecorator><WarningAmberRoundedIcon style={{ color: 'orange' }} /></ListItemDecorator> Wybrano płatność przelewem, ale nie załączono numeru konta na fakturze.</ListItem>) : ''}
        {warnComment ? (<ListItem><ListItemDecorator><WarningAmberRoundedIcon style={{ color: 'orange' }} /></ListItemDecorator> Zaznaczono dodanie uwag do faktury, ale nie wpisano ich treści.</ListItem>) : ''}
      </List>
    );

    return { errors, warnings, errList };
  };

  // ------------------ wczytujemy kontrahentów z bazy -----------
  const [contractors, setContractors] = React.useState([]);

  function getContractors() {
    fetch('https://be.factoorian.pl/contractors/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setContractors(JSON.parse(data));
        setIsLoading(false);
        setLoaded(true);
        console.log(JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getContractors();
  }, []);

  function getContractorsList() {
    const optionList = contractors.map((contr) =>
      <Option value={contr['contr_id']}>
        {contr['name']}{contr['contr_type']===1?<Typography textColor='grey' level='body-sm'> {' (NIP: ' + contr['nip'] + ')'} </Typography>:null}
      </Option>
    );

    return optionList;
  }

  // ------------------ wczytujemy towary z bazy -----------
  const [goods, setGoods] = React.useState([]);

  function getGoods() {
    fetch('https://be.factoorian.pl/library-goods/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setGoods(JSON.parse(data));
        setIsLoading(false);
        setLoaded(true);
        console.log(JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getGoods();
  }, []);

  function getGoodsList() {

    const optionList = goods.map((good) =>
      <Option value={good['lib_id']}>
        {good['value_char'] + ' (cena jedn.:' + (good['value_nr'] as number).toString() + ')'}
      </Option>
    );

    return optionList;
  }

  // ------------------ wczytujemy stawki podatku z bazy -----------
  function getTaxes() {
    fetch('https://be.factoorian.pl/taxes')
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setTaxes(JSON.parse(data));
        console.log(JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getTaxes();
  }, []);

  function getTaxesList() {
    let reducedTaxes = [];
    taxes.map((tax) => {
      if (chArray.indexOf(tax['tax_id']) > -1)
        reducedTaxes.push(tax);
    })
    reducedTaxes.sort((a, b) => a['tax_value'] - b['tax_value']);

    console.log("Reduced: ", reducedTaxes);
    const optionList = reducedTaxes.map((tax) =>
      //      if (chArray.indexOf(tax['tax_id']) > -1) {
      //        console.log("Well");
      <Option value={tax['tax_id']}>
        {tax['tax_name']}
      </Option>
      //      }

    );
    console.log("Taxes: ", chArray, optionList);
    return optionList;
  }

  // ------------------ wczytujemy jednostki miary z bazy -----------
  const [units, setUnits] = React.useState([]);

  function getUnits() {
    fetch('https://be.factoorian.pl/library-units/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setUnits(JSON.parse(data));
        setIsLoading(false);
        setLoaded(true);
        console.log(JSON.parse(data));
      });
  }

  React.useEffect(() => {
    getUnits();
  }, []);

  function getUnitsList() {

    const optionList = units.map((vunit) =>
      <Option value={vunit['lib_id']}>
        {vunit['value_char'] + ' (' + vunit['comment'] + ')'}
      </Option>
    );

    return optionList;
  }

  // ------------------ dodajemy fakturę do bazy -----------
  // eslint-disable-next-line
  const [newInv, setNewInv] = React.useState([]);
//  const [pdfFile, setPdfFile] = React.useState<string>('');

  function addInvoice() {
    //    if (invValidate().errors > 0) {
    //      setOpenError(true);
    //      return;
    //    }
    let sNetto = getSums().sumaNetto;
    let sBrutto = getSums().sumaBrutto;
    let sTax = getSums().sumaPodatek;

    let xbody = JSON.stringify({ invNumber, invDate, sellDate, payDays, paymentForm, paymentDiv, contrId, sNetto, sBrutto, sTax, rows, pdfFile });
    console.log(xbody);
    fetch(`https://be.factoorian.pl/invoices/` + comp_id.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ invNumber, invDate, sellDate, payDays, paymentForm, paymentDiv, contrId, sNetto, sBrutto, sTax, rows, pdfFile }),
    })
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        setNewInv(JSON.parse(data));
        console.log(JSON.parse(data));
        //       getMerchant();
      });
  }

  function pdfPreview() {
    if (invValidate().errors > 0) {
      setOpenError(true);
      return;
    }
    setOpen2(true);
  }

  const [settingsC, setSettingsC] = React.useState([]);
  const [taxes, setTaxes] = React.useState([]);
  const [autonr, setAutoNr] = React.useState(true);
  const [nextnr, setNextNr] = React.useState(1);
  const [autoFormat, setAutoFormat] = React.useState(1);
  const [autoCycle, setAutoCycle] = React.useState(1);

  const [chArray, setChArray] = React.useState<number[]>([]);
  const [defTax, setDefTax] = React.useState(1);

  // pobieramy ustawienia firmy
  async function getSettingsCompany() {
    fetch('https://be.factoorian.pl/settings-company/' + comp_id.toString())
      .then(response => {
        let output = response.text();
        console.log(output);
        return output;
      })
      .then(data => {
        const jsonData = JSON.parse(data)
        setSettingsC(jsonData);
        if (chArray.length > 0) {
          chArray.splice(0, chArray.length);
        }
        chArray.push(...jsonData[0]['tax_hide']);
        console.log("Settings data: ", jsonData);
        setDefTax(jsonData[0]['tax_default']);
        setTaxId(jsonData[0]['tax_default']);
        setAutoFormat(jsonData[0]['autoformat']);
        setAutoCycle(jsonData[0]['autocycle']);
        setAutoNr(jsonData[0]['autonumer']);
        setNextNr(jsonData[0]['nextnr']);
        setInvNumber(jsonData[0]['autonumer'] ? formatInvNumber(jsonData[0]['autoformat'], jsonData[0]['nextnr']) : '');
      });
  }

  React.useEffect(() => {
    getSettingsCompany();
  }, []);

  function formatInvNumber(format: number, next: number) {
    let mies = (new Date()).toISOString().substring(5, 7);
    let rok = (new Date()).toISOString().substring(0, 4);
    let result = '';
    if (format === 1)  // nr/mies/rok
    {
      result = next.toString() + '/' + mies + '/' + rok;
    }
    else  // rok/mies/nr
    {
      result = rok + '/' + mies + '/' + next.toString()
    }
    console.log("Autonumber: ", result)
    return result;
  }

  let pdfFile = '';

  async function handleUploadInv(fileData: Blob | null) {
    console.log("PDF: ", fileData);
    if (!fileData) return;
    const formData = new FormData();
    formData.append("file", fileData);
    const res = await axios.post("https://be.factoorian.pl/uploadInv", formData, {
      onUploadProgress: (progressEvent) => {
        console.log(
          "PDF Upload progress: " +
          Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          ) +
          "%"
        );
      },
    });
    console.log(res);
  //  setPdfFile(res.data.filename);
    pdfFile = res.data.filename;
    //    getFile(res.data.filename);
    //    setChanged(true);
  }

  async function incrementInvNr() {
    fetch(`https://be.factoorian.pl/settings-company-inc/` + comp_id.toString(), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.text();
      })
      .then(data => {
        alert(data);
      });
  }

  async function finishInvoice() {
    await handleUploadInv(invFile);
    addInvoice();
    await incrementInvNr();
    console.log("Prev:", nextnr);
    const nextX = nextnr + 1;
    setNextNr(nextX);
    setOpen2(false);
//    cleanUp();
    rows.splice(0);
//    await getSettingsCompany();
    setContrId(null);
    const newStr = formatInvNumber(autoFormat, nextX);
    console.log("next clean:", newStr);
    setInvNumber(autonr ? newStr : '');
  }

  async function cleanUp()
  {
    rows.splice(0);
//    await getSettingsCompany();
    setContrId(null);
    const newStr = formatInvNumber(autoFormat, nextnr);
    console.log("next clean:", newStr);
    setInvNumber(autonr ? newStr : '');

  }

  //----------------------------------------------------------------------------------    
  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          //         zIndex: 9995,
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Stack direction='row' spacing={1}>
              <HomeRoundedIcon color='primary' fontSize='small' />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Faktury
              </Typography>
              <ChevronRightRoundedIcon color='primary' fontSize="small" />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Nowa faktura
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h3" component="h1" sx={{ mt: 1, mb: 2 }}>
            Nowa faktura
          </Typography>
        </Box>
      </Box>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          //        maxWidth: '1200px',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Dane ogólne</Typography>
            <Typography level="body-sm">
              Wprowadź podstawowe dane dotyczące faktury.
            </Typography>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >

            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={2}>
                <Stack spacing={2} direction='row' alignItems='center'>
                  <Input size="sm" placeholder="Numer faktury"
                    value={invNumber}
                    disabled={autonr}
                    onChange={(e) => setInvNumber(e.target.value)}
                  />
                  <Typography level="title-md"><Tooltip arrow size='sm' variant='solid' color='danger' title={<Box sx={{ maxWidth: 300, textAlign: 'justify' }}><Typography>{autonr ? 'Numer nadawany jest automatycznie. Możesz to zmienić w ustawieniach, jednak pamiętaj, że wszelkie modyfikacje sposobu numeracji mogą skutkować nieprawidłowościami w tym zakresie!' : 'Autonumeracja jest wyłączona, wprowadź ręcznie numer faktury'}</Typography></Box>}><InfoIcon sx={{ color: 'lightgrey', fontSize: 15 }} /></Tooltip></Typography>
                </Stack>

                <Divider />

                <Stack direction='row' spacing={4}>
                  <Stack direction='column' spacing={1}>
                    <Typography level="body-sm">
                      Data wystawienia faktury
                    </Typography>
                    <Input
                      type='date'
                      value={invDate}
                      required
                      onChange={(e) => setInvDate(e.target.value)}
                    />
                  </Stack>

                  <Stack direction='column' spacing={1}>
                    <Typography level="body-sm">
                      Data sprzedaży
                    </Typography>
                    <Input
                      type='date'
                      value={sellDate}
                      required
                      onChange={(e) => setSellDate(e.target.value)}
                    />
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Typography level="body-sm">
                      Termin płatności:
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems='center'>
                      <Input
                        size="sm"
                        type="number"
                        sx={{ maxWidth: 70 }}
                        placeholder="dni"
                        value={payDays}
                        onChange={(e) => setPayDays(e.target.valueAsNumber)}
                      />
                      <Typography level="body-sm">
                        dni
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Typography level="body-sm">
                      Forma płatności:
                    </Typography>
                    <Select
                      size="sm"
                      placeholder="Forma płatności"
                      value={paymentForm}
                      onChange={(e, newValue) => {
                        setPaymentForm(newValue);
                        if (newValue === 2)
                          setShowBank(false);
                      }
                      }
                    >
                      <Option value={1}>
                        przelew
                      </Option>
                      <Option value={2}>
                        gotówka
                      </Option>
                    </Select>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Typography level="body-sm">
                      Płatność podzielona:
                    </Typography>
                    <Select
                      size="sm"
                      placeholder="Forma płatności"
                      value={paymentDiv}
                      onChange={(e, newValue) => setPaymentDiv(newValue)}
                    >
                      <Option value={1}>
                        NIE
                      </Option>
                      <Option value={2}>
                        TAK
                      </Option>
                    </Select>
                  </Stack>

                </Stack>

                <FormControl>
                  <CheckBox
                    defaultChecked
                    checked={showBank}
                    label={
                      <Typography level="body-sm">Umieść numer konta na fakturze</Typography>
                    }
                    onChange={(e) => setShowBank(!showBank)}
                  />
                </FormControl>

                <Divider />

                <div>
                  <FormControl sx={{ display: { sm: 'contents' } }}>
                    <FormLabel>Kontrahent</FormLabel>

                    <Select
                      size="sm"
                      startDecorator={<BusinessIcon />}
                      placeholder="Wybierz kontrahenta"
                      value={contrId}
                      onChange={(e, newValue) => setContrId(newValue)}
                    >
                      {getContractorsList()}

                    </Select>
                  </FormControl>
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Card>


        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Sprzedaż</Typography>
            <Typography level="body-sm">
              Wprowadź poszczególne pozycje dla faktury
            </Typography>
          </Box>
          <Divider />

          <Stack spacing={2} sx={{ my: 1 }}>

            <Box>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                  '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                  '--Table-headerUnderlineThickness': '1px',
                  '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                  '--TableCell-paddingY': '4px',
                  '--TableCell-paddingX': '8px',
                }}>
                <thead>
                  <tr>
                    <th style={{ width: '40%', textAlign: 'left', padding: '12px 6px' }}>Towar</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px', whiteSpace: 'normal' }}>Cena netto</th>
                    <th style={{ width: '10%', textAlign: 'center', padding: '12px 6px', whiteSpace: 'normal' }}>Stawka podatku</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px' }}>Ilość</th>
                    <th style={{ width: '10%', textAlign: 'center', padding: '12px 6px' }}>Jednostka</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px', whiteSpace: 'normal' }}>Wartość netto</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px', whiteSpace: 'normal' }}>Wartość brutto</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row) => (
                    <tr
                      key={row.tableKey}
                    >
                      <td style={{ textAlign: 'left' }}><Typography level="body-xs">{(goods.find(({ lib_id }) => lib_id === row.goodId))['value_char']}</Typography></td>
                      <td style={{ textAlign: 'right' }}><Typography level="body-xs">{(row.nettoOne).toFixed(2)}</Typography></td>
                      <td style={{ textAlign: 'center' }}><Typography level="body-xs">{(taxes.find(({ tax_id }) => tax_id === row.taxId))['tax_name']}</Typography></td>
                      <td style={{ textAlign: 'right' }}><Typography level="body-xs">{row.count}</Typography></td>
                      <td style={{ textAlign: 'center' }}><Typography level="body-xs">{(units.find(({ lib_id }) => lib_id === row.unitId))['value_char']}</Typography></td>
                      <td style={{ textAlign: 'right' }}><Typography level="body-xs">{(row.nettoAll).toFixed(2)}</Typography></td>
                      <td style={{ textAlign: 'right' }}><Typography level="body-xs">{(row.bruttoAll).toFixed(2)}</Typography></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>



            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<Add />}
              onClick={() => setOpen(true)}>
              Nowa pozycja...
            </Button>

            <Box>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                  '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                  '--Table-headerUnderlineThickness': '1px',
                  '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                  '--TableCell-paddingY': '4px',
                  '--TableCell-paddingX': '8px',
                }}>
                <thead>
                  <tr>
                    <th style={{ width: '90%', textAlign: 'right', padding: '12px 6px' }}>Suma brutto</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px' }}>{getSums().sumaBrutto.toFixed(2)}</th>
                  </tr>
                  <tr>
                    <th style={{ width: '90%', textAlign: 'right', padding: '12px 6px' }}>Podatek VAT</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px' }}>{getSums().sumaPodatek.toFixed(2)}</th>
                  </tr>
                  <tr>
                    <th style={{ width: '90%', textAlign: 'right', padding: '12px 6px' }}>Suma netto</th>
                    <th style={{ width: '10%', textAlign: 'right', padding: '12px 6px' }}>{getSums().sumaNetto.toFixed(2)}</th>
                  </tr>
                </thead>
              </Table>
            </Box>

            <Divider />

            <FormControl>
              <CheckBox label={
                <Typography level="body-sm">Dodaj uwagi</Typography>
              }
                onChange={(e) => setCommentOn(e.target.checked)}
              />
            </FormControl>

            <FormControl
              sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
            >
              <Input size="sm" placeholder="Tutaj wpisz uwagi, które zamieszczone zostaną u dołu faktury..."
                value={comment}
                disabled={!commentOn}
                required
                onChange={(e) => setComment(e.target.value)}
              />
            </FormControl>

            <Modal open={open} onClose={() => setOpen(false)}>
              <ModalDialog>
                <DialogTitle>Dodaj nową pozycję</DialogTitle>
                <Divider />
                <form
                  onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    if (posValidate().errors > 0) {
                      setOpenErrorPos(true);
                    }
                    else {
                      rows.push(createData(
                        tKey,
                        goodId,
                        (goodId != null ? (goods.find(({ lib_id }) => lib_id === goodId))['value_char'] : ""),
                        goodValue,
                        taxId,
                        (taxId != null ? (taxes.find(({ tax_id }) => tax_id === taxId))['tax_value'] : 0),
                        (taxId != null ? (taxes.find(({ tax_id }) => tax_id === taxId))['tax_name'] : ""),
                        goodCount,
                        unitId,
                        (unitId != null ? (units.find(({ lib_id }) => lib_id === unitId))['value_char'] : ""),
                        (unitId != null ? (units.find(({ lib_id }) => lib_id === unitId))['comment'] : ""),
                        (goodValue * goodCount),
                        ((taxId != null ? (taxes.find(({ tax_id }) => tax_id === taxId))['tax_value'] : 0) / 100) * (goodValue * goodCount) + (goodValue * goodCount))
                      );

                      setTKey(tKey + 1);
                      console.log(rows);

                      resetModal();
                      setOpen(false);
                    }
                  }}
                >
                  <Stack spacing={2}>
                    <FormControl>
                      <FormLabel>Towar / usługa</FormLabel>
                      <Select
                        placeholder="Wybierz..."
                        indicator={<KeyboardArrowDown />}
                        value={goodId}
                        renderValue={() => ((goods.find(({ lib_id }) => lib_id === goodId))['value_char'])}
                        onChange={(e, newValue) => setGoodId(newValue)}
                        sx={{
                          width: 500,
                          [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                              transform: 'rotate(-180deg)',
                            },
                          },
                        }}
                      >

                        {getGoodsList()}

                      </Select>
                    </FormControl>


                    <Stack direction='row' spacing={2} display='flex' width={500}>
                      <Stack width='50%'>
                        <FormControl>
                          <FormLabel>Ilość</FormLabel>
                          <Input
                            type='number'
                            value={goodCount}
                            required
                            onChange={(e) => setGoodCount(e.target.valueAsNumber)}
                          />
                        </FormControl>
                      </Stack>
                      <Stack width='50%' maxWidth='50%'>
                        <FormControl>
                          <FormLabel>Jednostka miary</FormLabel>
                          <Select
                            placeholder="Wybierz..."
                            indicator={<KeyboardArrowDown />}
                            value={unitId}
                            renderValue={() => ((units.find(({ lib_id }) => lib_id === unitId))['value_char'])}
                            onChange={(e, newValue) => setUnitId(newValue)}
                            sx={{
                              [`& .${selectClasses.indicator}`]: {
                                transition: '0.2s',
                                [`&.${selectClasses.expanded}`]: {
                                  transform: 'rotate(-180deg)',
                                },
                              },
                            }}
                          >

                            {getUnitsList()}

                          </Select>

                        </FormControl>
                      </Stack>
                    </Stack>

                    <Stack direction='row' spacing={2} display='flex' width={500}>
                      <Stack width='50%'>
                        <FormControl>
                          <FormLabel>Cena jednostkowa netto</FormLabel>
                          <Input
                            type='number'
                            value={goodValue}
                            required
                            onChange={(e) => setGoodValue(e.target.valueAsNumber)}

                          />
                        </FormControl>
                      </Stack>

                      <Stack width='50%'>
                        <FormControl>
                          <FormLabel>Stawka podatku</FormLabel>
                          <Select
                            placeholder="Wybierz stawkę..."
                            indicator={<KeyboardArrowDown />}
                            //defaultValue={1}
                            value={taxId}
                            onChange={(e, newValue) => setTaxId(newValue)}
                            sx={{
                              [`& .${selectClasses.indicator}`]: {
                                transition: '0.2s',
                                [`&.${selectClasses.expanded}`]: {
                                  transform: 'rotate(-180deg)',
                                },
                              },
                            }}
                          >

                            {getTaxesList()}

                          </Select>
                        </FormControl></Stack>
                    </Stack>
                    <Divider />
                    <Button type="submit">Dodaj</Button>
                  </Stack>


                </form>
              </ModalDialog>
            </Modal>

          </Stack>
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>

{/*              <Button size="sm" variant="outlined" color="neutral" onClick={() => {
                invUp = false;
                setOpen2(true)
              }}>
                Generuj PDF
              </Button>  */}
              <Button size="sm" variant="solid" onClick={() => {
                if (invValidate().errors > 0) {
                  setOpenError(true);
                }
                else {
                  invUp = false;
                  setOpen2(true);
                }
                //addInvoice()
              }}>
                Wystaw fakturę
              </Button>
              <Modal open={open2} onClose={() => setOpen2(false)}>
                <ModalDialog>
                  <DialogTitle>Podgląd faktury...</DialogTitle>
                  <div>

                    <Box minHeight={900} minWidth={600}>

                      <PDFViewer height={900} width={600}>
                        <Quixote />
                      </PDFViewer>
                    </Box>

                    <BlobProvider document={<Quixote />}>
                      {({ blob, url, loading, error }) => {
                        if (!invUp && blob) {
                          invFile = blob;
                          //handleUploadInv(blob);
                          invUp = true;
                        }
                        return (<Stack direction='row' spacing={2} alignItems='flex-end'>
                          <Button size="sm" variant="outlined" color="neutral" onClick={() => { setOpen2(false) }}>
                            Anuluj
                          </Button>
                          <Button size="sm" variant="solid" color="primary" disabled={loading} onClick={() => {
                              finishInvoice();
                              //cleanUp();
                          }}>
                            Zapisz fakturę
                          </Button>
                        </Stack>)
                          ;
                      }}
                    </BlobProvider>

                    {/*                    <PDFDownloadLink document={<Quixote />} fileName="somename.pdf">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                      }
                    </PDFDownloadLink>   */}

                  </div>
                </ModalDialog>
              </Modal>

              <Modal open={openError} onClose={() => setOpenError(false)}>
                <ModalDialog>
                  <DialogTitle>Nie mozna dodać faktury!</DialogTitle>
                  {invValidate().errList()}
                </ModalDialog>
              </Modal>

              <Modal open={openErrorPos} onClose={() => setOpenErrorPos(false)}>
                <ModalDialog>
                  <DialogTitle>Nie mozna dodać pozycji!</DialogTitle>
                  {posValidate().errList()}
                </ModalDialog>
              </Modal>

            </CardActions>
          </CardOverflow>
        </Card>
      </Stack>
    </Box>
  );
}