import * as React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';

import Sidebar from './components/Sidebar.tsx';
import OrderTable from './components/OrderTable.tsx';
import Header from './components/Header.tsx';
import NewInvoice from './components/NewInvoice.tsx';
import LibGoods from './components/LibGoods.tsx';
import LibUnits from './components/LibUnits.tsx';
import Contractors from './components/Contractors.tsx';
import MyProfile from './components/testProfile.tsx';
import Login from './components/Login.tsx';
import MainFrame from './components/MainFrame.tsx';
import NewAccount from './components/NewAccount.tsx';
import SettingsCompany from './components/SettingsCompany.tsx';

// ----- login page -------

import './App.css'
import { useEffect, useState } from 'react'

/*
export default function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState('')

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
*/

// -----------------------

export default function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [compID, setCompID] = useState(0);
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState(0);

  return (
    <>

    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} setCompID={setCompID} setUserName={setUserName} setRole={setRole}/>} />
        <Route path="main" element={loggedIn?<MainFrame compID={compID} email={email} userName={userName} setLoggedIn={setLoggedIn} role={role} loggedIn={loggedIn}/>:<Login setLoggedIn={setLoggedIn} setEmail={setEmail} setCompID={setCompID} setUserName={setUserName} setRole={setRole}/>}>
          <Route path="newinvoice" element={<NewInvoice compID={compID}/>} />
          <Route path="orders" element={<OrderTable compID={compID}/>} />
          <Route path="profile" element={<MyProfile compID={compID}/>} />
          <Route path="libgoods" element={<LibGoods compID={compID}/>} />
          <Route path="libunits" element={<LibUnits compID={compID}/>} />
          <Route path="contractors" element={<Contractors compID={compID}/>} />
          <Route path="newaccount" element={<NewAccount/>} />
          <Route path="settings_c" element={<SettingsCompany compID={compID}/>} />
        </Route>
      </Routes>

    </BrowserRouter>
    </>
  );
}