import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { useNavigate } from 'react-router-dom';
import AspectRatio from '@mui/joy/AspectRatio';


import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
//import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';

import ColorSchemeToggle from './ColorSchemeToggle.tsx';
import { closeSidebar } from '../utils.ts';

import flogo from './factoorian_logo3.png';

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar(props) {
  console.log("Sidebar: ", props)
  const navigate = useNavigate();

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',

        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <AspectRatio
        ratio="1"
        variant='outlined'
        maxHeight={50}
        objectFit='contain'
      >
        <img
          src={flogo}
          alt="logo"
        />
      </AspectRatio>

      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton onClick={() => navigate("/main/profile")}>
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Moja firma</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem >
            <ListItemButton disabled={true}>
              <DashboardRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm" textColor={'grey'}>Tablica</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>


          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <RequestQuoteRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Faktury</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton onClick={() => navigate("/main/newinvoice")}>Nowa faktura</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => navigate("/main/orders")}>Moje faktury</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton disabled={true}>Faktury kosztowe</ListItemButton>
                </ListItem>
                
              </List>
            </Toggler>
          </ListItem>  

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton disabled={false} onClick={() => setOpen(!open)}>
                  <LibraryBooksRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm" >Biblioteki</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton onClick={() => navigate("/main/contractors")}>Kontrahenci</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => navigate("/main/libgoods")}>Towary i usługi</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton  onClick={() => navigate("/main/libunits")}>Jednostki miary</ListItemButton>
                </ListItem>
                
              </List>
            </Toggler>
          </ListItem>  
          <Divider/>
          <ListItem>
            <ListItemButton onClick={() => navigate("/main/settings_c")}>
              <SettingsRoundedIcon />
              Ustawienia
            </ListItemButton>
          </ListItem>


          {(props.role === 100)?(
          <>
          <Divider />
          <ListItem>
            <ListItemButton onClick={() => navigate("/main/newaccount")}>
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Nowe konto</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem></>):(<div/>)
          }
        </List>

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >

        </List>

        <ColorSchemeToggle />


      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <AccountCircleIcon color='primary' sx={{fontSize: 40}}/>
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{props.userName}</Typography>
          <Typography level="body-xs">{props.email}</Typography>
        </Box>
        <IconButton
         size="sm"
         variant="plain"
         color="neutral"
         onClick={() => {
          props.setLoggedIn(false);
          navigate('/');
         }}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}