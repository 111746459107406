import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Table from '@mui/joy/Table';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { IconButton } from '@mui/joy';


export default function LibUnits(props) {
  const comp_id = props.compID;

  const [open, setOpen] = React.useState<boolean>(false);
  const [unitShort, setUnitShort] = React.useState<string>('');
  const [unitComment, setUnitComment] = React.useState<string>('');

  const [xRow, setRow] = React.useState<number>(0);
  const [rowTextShort, setRowTextShort] = React.useState<string>('');
  const [rowTextComment, setRowTextComment] = React.useState<string>('');

  // ------------------ dodajemy jednostkę do biblioteki -----------
  function addUnit() {
    let xbody = JSON.stringify({ unitShort, unitComment });
    console.log(xbody);
    fetch(`https://be.factoorian.pl/library-units/` + comp_id.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ unitShort, unitComment }),
    })
      .then(response => {
        let output = response.text();
        console.log(output);
        resetInput();
        return output;
      })
      .then(data => {
         console.log(JSON.parse(data));
        getUnits();
      });
  }

 // ------------------ wczytujemy jednostki miary z biblioteki -----------
 const [units, setUnits] = React.useState([]);

 function getUnits() {
   fetch('https://be.factoorian.pl/library-units/' + comp_id.toString())
     .then(response => {
       let output = response.text();
       console.log(output);
       return output;
     })
     .then(data => {
       setUnits(JSON.parse(data));
       console.log(JSON.parse(data));
     });
 }

 React.useEffect(() => {
   getUnits();
 }, []);

  // ------------------ usuwamy towar z biblioteki -----------
  function removeUnit(id: number) {
    let xbody = JSON.stringify({ id });
    console.log(xbody);
    fetch(`https://be.factoorian.pl/library-units/` + comp_id.toString(), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then(response => {
        let output = response.text();
        console.log(output);
//        resetInput();
        return output;
      })
      .then(data => {
        console.log(JSON.parse(data));
        getUnits();
      });
  }

  function resetInput() {
    setUnitShort('');
    setUnitComment('');
  }

  //----------------------------------------------------------------------------------    
  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant='outlined' role='alertdialog'>
          <DialogTitle><WarningAmberRoundedIcon sx={{ color: 'darkred' }}/> <Typography textColor='darkred'> Usuwasz pozycję!</Typography></DialogTitle>
          <Divider/>
          <DialogContent><Typography textAlign='center' textColor='grey'>Czy na pewno chcesz usunąć jednostkę</Typography><Typography textAlign='center'>{rowTextShort} ({rowTextComment}) ?</Typography></DialogContent>
          <Divider/>
            <Stack spacing={2} direction='row' alignItems='center' justifyContent='center'>

              <Button
                color='danger'
                variant='soft'
                onClick={() => {
                  setOpen(false);
                }}>
                Nie
              </Button>

              <Button 
                color='danger'
                onClick={() => {
                  removeUnit(xRow);
                  setOpen(false);
                }}>
                Tak
              </Button>

            </Stack>

        </ModalDialog>
      </Modal>



      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          //         zIndex: 9995,
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Stack direction='row' spacing={1}>
              <HomeRoundedIcon color='primary' fontSize='small' />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Biblioteki
              </Typography>
              <ChevronRightRoundedIcon color='primary' fontSize="small" />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Jednostki miary
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h3" component="h1" sx={{ mt: 1, mb: 2 }}>
            Lista jednostek miary
          </Typography>
        </Box>
      </Box>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          //        maxWidth: '1200px',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >

        <Card>
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >
            <Stack spacing={2} direction='column' width={'100%'}>

              <Stack direction='row' spacing={4}>

                <Stack direction='column' spacing={1} width={'20%'}>
                  <Typography level="body-sm">
                    Skrót jednostki
                  </Typography>
                  <Input
                    value={unitShort}
                    required
                    onChange={(e) => setUnitShort(e.target.value)}
                  />
                </Stack>

                <Stack direction='column' spacing={1} width={'80%'}>
                  <Typography level="body-sm">
                    Opis jednostki miary
                  </Typography>
                  <Input
                    value={unitComment}
                    onChange={(e) => setUnitComment(e.target.value)}
                  />
                </Stack>

              </Stack>
            </Stack>
          </Stack>

          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <Button size="sm" variant="solid" onClick={() => addUnit()} disabled={unitShort === ''}>
                Dodaj jednostkę miary
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>


        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Zestawienie</Typography>
          </Box>
          <Divider />

          <Stack spacing={2} sx={{ my: 1 }}>

            <Box>
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                  '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                  '--Table-headerUnderlineThickness': '1px',
                  '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                  '--TableCell-paddingY': '4px',
                  '--TableCell-paddingX': '8px',
                }}>
                <thead>
                  <tr>
                    <th style={{ width: '20%', textAlign: 'left', padding: '12px 6px' }}>Skrót</th>
                    <th style={{ width: '75%', textAlign: 'left', padding: '12px 6px' }}>Opis</th>
                    <th style={{ width: '5%', textAlign: 'center', padding: '12px 6px' }}>&nbsp;</th>

                  </tr>
                </thead>
                <tbody>
                  {units.map((row) => (
                    <tr
                      key={row['lib_id']}
                    >
                      <td style={{ textAlign: 'left' }}><Typography level="body-xs">{row['value_char']}</Typography></td>
                      <td style={{ textAlign: 'left' }}><Typography level="body-xs">{row['comment']}</Typography></td>
                      <td style={{ textAlign: 'center' }}>
                        <IconButton onClick={
                          () => {
                            setRow(row['lib_id']);
                            setRowTextShort(row['value_char']);
                            setRowTextComment(row['comment']);
                            setOpen(true);
                          }
                        }>
                          <HighlightOffRoundedIcon sx={{ "&:hover": { color: "red" }, fontSize: '18px' }} />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
}