import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import CheckBox from '@mui/joy/Checkbox';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Table from '@mui/joy/Table';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import { IconButton } from '@mui/joy';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function NewAccount(props) {
  const comp_id = 1;

  const [open, setOpen] = React.useState<boolean>(false);
  const [goodName, setGoodName] = React.useState<string>('');
  const [goodPrice, setGoodPrice] = React.useState<number>(0);
  const [goodType, setGoodType] = React.useState<number | null>(1);

  const [xRow, setRow] = React.useState<number>(0);
  const [rowText, setRowText] = React.useState<string>('');

  const navigate = useNavigate();

  let nuID = 0;
  let ncID = 0;

  const checkAccountExists = (callback) => {
    fetch('https://be.factoorian.pl/check-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((r) => r.json())
      .then((r) => {
        callback(r?.userExists)
      })
  }

  // Log in a user using email and password
  const signIn = () => {
    fetch('https://be.factoorian.pl/sign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, passwd }),
    })
      .then((r) => r.json())
      .then((r) => {
        nuID = r[0]['user_id'];
        console.log("NewAccount signIn result: ", nuID);
        setNewUserID(nuID);
        if (addCompany) {
          addEmptyCompany();
        }
      })

  }

  const addEmptyCompany = () => {
    fetch('https://be.factoorian.pl/companies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((r) => r.json())
      .then((r) => {
        ncID = r[0]['comp_id'];
        setNewCompID(ncID);
        console.log("NewAccount addEmptyCompany result: ", ncID);
        addRole();
        addSettings();
      })
  }

  const addRole = () => {
    fetch('https://be.factoorian.pl/roles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ nuID, ncID }),
    })
      .then((r) => r.json())
      .then((r) => {
        console.log("NewAccount role result: ", r);
      })
  }

  const addSettings = () => {
    fetch('https://be.factoorian.pl/settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ nuID, ncID, chArray:[1,2,3,4,5], defTax: 1, autonr: true, nextnr: 1, autoFormat: 1, autoCycle: 3}),
    })
      .then((r) => r.json())
      .then((r) => {
        console.log("NewAccount settings result: ", r);
      })
  }

  function resetInput() {
    setEmail('');
    setPasswd('');
  }

  const [email, setEmail] = React.useState<string>('');
  const [passwd, setPasswd] = React.useState<string>('');
  const [passwd2, setPasswd2] = React.useState<string>('');
  const [addCompany, setAddCompany] = React.useState<boolean>(true);
  const [newUserID, setNewUserID] = React.useState(0);
  const [newCompID, setNewCompID] = React.useState(0);

  //----------------------------------------------------------------------------------    
  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant='outlined' role='alertdialog'>
          <DialogTitle><WarningAmberRoundedIcon sx={{ color: 'darkred' }} /> <Typography textColor='darkred'> Usuwasz pozycję!</Typography></DialogTitle>
          <Divider />
          <DialogContent><Typography textAlign='center' textColor='grey'>Czy na pewno chcesz usunąć towar</Typography><Typography textAlign='center'>{rowText} ?</Typography></DialogContent>
          <Divider />
          <Stack spacing={2} direction='row' alignItems='center' justifyContent='center'>

          </Stack>
        </ModalDialog>
      </Modal>



      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          //         zIndex: 9995,
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
          }}
        >
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Stack direction='row' spacing={1}>
              <HomeRoundedIcon color='primary' fontSize='small' />
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Nowe konto
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h3" component="h1" sx={{ mt: 1, mb: 2 }}>
            Nowe konto (tylko administrator)
          </Typography>
        </Box>
      </Box>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          //        maxWidth: '1200px',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >

        <Card>
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >


            <Box
              component="main"
              sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                },
                [`& .MuiFormLabel-asterisk`]: {
                  visibility: 'hidden',
                },
              }}
            >

              <Stack gap={4} sx={{ mt: 2 }}>
                <form
                  onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                    event.preventDefault();
                    checkAccountExists((accountExists) => {
                      // If yes, log in
                      console.log("Konto istnieje: ", accountExists);
                      if (accountExists) {
                        alert("Konto juz istnieje!");
                      }
                      else {
                        signIn();
                        console.log("addCompany: ", addCompany, ", newUserID: ", newUserID)
                      }
                    })
                  }}
                >
                  <FormControl required>
                    <FormLabel>E-mail</FormLabel>
                    <Input size="sm" onChange={(e) => setEmail(e.target.value)} type="email" name="email" value={email} sx={{ minWidth: 300 }} />
                  </FormControl>
                  <FormControl required>
                    <FormLabel>Hasło</FormLabel>
                    <Input size="sm" onChange={(e) => setPasswd(e.target.value)} type="password" name="password" value={passwd} />
                  </FormControl>
                  <FormControl required>
                    <FormLabel>Powtórz hasło</FormLabel>
                    <Input size="sm" onChange={(e) => setPasswd2(e.target.value)} type="password" name="password2" value={passwd2} />
                  </FormControl>
                  <FormControl>
                    <CheckBox

                      checked={addCompany}
                      label={
                        <Typography level="body-sm">Dodaj pustą firmę dla konta</Typography>
                      }
                      onChange={(e) => setAddCompany(!addCompany)}
                    />
                  </FormControl>
                  <Stack gap={4} sx={{ mt: 2 }}>

                    <Button type="submit">
                      Utwórz konto
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Box>
          </Stack>
        </Card>

      </Stack>
    </Box>
  );
}