import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';

import Sidebar from './Sidebar.tsx';
import Header from './Header.tsx';

export default function MainFrame(props) {
    console.log("MainFrame: ", props)

    const navigate = useNavigate();

    /*   React.useEffect(() => {
       if (!props.loggedIn) {
           navigate('/');
           return;
       }})
   */


        return (
            <>
                <CssVarsProvider disableTransitionOnChange>
                    <CssBaseline />
                    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

                        <Sidebar email={props.email} userName={props.userName} setLoggedIn={props.setLoggedIn} role={props.role} />
                        <Header />

                        <Box
                            component="main"
                            className="MainContent"
                            sx={{
                                px: { xs: 2, md: 6 },
                                pt: {
                                    xs: 'calc(12px + var(--Header-height))',
                                    sm: 'calc(12px + var(--Header-height))',
                                    md: 3,
                                },
                                pb: { xs: 2, sm: 2, md: 3 },
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0,
                                height: '100dvh',
                                gap: 1,
                                overflow: 'auto'
                            }}
                        >

                            <Outlet />

                        </Box>
                    </Box>

                </CssVarsProvider>
            </>
        )
}