import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';


const betaVersion = '0.004 (20240715)';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      {...other}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}


export default function Login(props) {

  const [email, setEmail] = React.useState<string>('');
  const [passwd, setPasswd] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(true);
  
 // const [loggedIn, setLoggedIn] = React.useState<boolean>(false);

  const navigate = useNavigate();
  
  const checkAccountExists = (callback) => {
    fetch('https://be.factoorian.pl/check-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((r) => r.json())
      .then((r) => {
        callback(r?.userExists)
      })
  }
  
  // Log in a user using email and password
  const logIn = () => {
    fetch('https://be.factoorian.pl/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, passwd }),
    })
      .then((r) => r.json())
      .then((r) => {
        if ('success' === r.message) {
          console.log("Login.tsx auth result: ", r)
          localStorage.setItem('user', JSON.stringify({ email, token: r.token }))
          props.setLoggedIn(true)
          props.setEmail(email)
          props.setCompID(r.compID)
          props.setUserName(r.userName)
          props.setRole(r.role)
          navigate('/main/profile')
        } else {
          window.alert('Wrong email or password')
        }
      })
  }

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
               <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog variant='outlined' role='alertdialog'>
                    <DialogTitle><WarningAmberRoundedIcon sx={{ color: 'darkred' }} /> <Typography textColor='darkred'> Ostrzeżenie!</Typography></DialogTitle>
                    <Divider />
                    <DialogContent><Typography textAlign='center' textColor='grey'>Pracujesz w wersji testowej systemu FACTOORIAN. Mimo dołożonych starań, system może zawierać błędy funkcjonalne oraz w kontekście bezpieczenstwa informacji. Rekomendujemy nie umieszczać w tej wersji systemu danych wrażliwych, a o wszelkich błędach w funkcjonowaniu prosimy informować na adres poczty elektronicznej [bugs@factoorian.pl], najlepiej wraz ze zrzutem ekranu. Dziękujemy!</Typography></DialogContent>
                    <Divider />
                    <Stack spacing={2} direction='row' alignItems='center' justifyContent='center'>

                        <Button
                            color='danger'
                            variant='soft'
                            onClick={() => {
                                setOpen(false);
                            }}>
                            Rozumiem
                        </Button>

                    </Stack>

                </ModalDialog>
            </Modal>

      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: '100%', md: '50vw' },
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >

            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography textAlign={'center'} component="h1" level="h4">
                  Witaj w FACTOORIAN!
                </Typography>
{/*                <Typography level="body-sm">
                  Pierwszy raz w FACTOORIAN?{' '}
                  <Link href="#replace-with-a-link" level="title-sm">
                    Załóz konto!
                  </Link>
          </Typography> */}
              </Stack>

            </Stack>
            <Divider/>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                  event.preventDefault();
                  checkAccountExists((accountExists) => {
                    // If yes, log in
                    console.log("Konto istnieje: ", accountExists);
                    if (!accountExists) {
                      alert("No such account!");
                    }
                    else {
                      logIn();
                    }
                    })
                }}
              >
                <FormControl required>
                  <FormLabel>E-mail</FormLabel>
                  <Input size="sm" onChange={(e) => setEmail(e.target.value)} type="email" name="email" value={email} sx={{minWidth: 250}}/>
                </FormControl>
                <FormControl required>
                  <FormLabel>Hasło</FormLabel>
                  <Input size="sm" onChange={(e) => setPasswd(e.target.value)} type="password" name="password" value={passwd}/>
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>

                  <Button type="submit" fullWidth>
                    Zaloguj
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              * beta {betaVersion} *
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: '50vw' },
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(https://demo.factoorian.pl/factoorian_login.png)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage: 'url(https://demo.factoorian.pl/factoorian_login.png)',
          },
        })}
      />
    </CssVarsProvider>
  );
}
