import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';


import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Table from '@mui/joy/Table';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BoyIcon from '@mui/icons-material/Boy';


import { Dropdown, IconButton, MenuButton, MenuItem, Menu } from '@mui/joy';


export default function Contractors(props) {
    const comp_id = props.compID;

    const [open, setOpen] = React.useState<boolean>(false);
    const [openNC, setOpenNC] = React.useState<boolean>(false);
    const [openNC_OF, setOpenNC_OF] = React.useState<boolean>(false);

    const [goodName, setGoodName] = React.useState<string>('');
    const [goodPrice, setGoodPrice] = React.useState<number>(0);
    const [goodType, setGoodType] = React.useState<number | null>(1);

    const [xRow, setRow] = React.useState<number>(0);
    const [rowText, setRowText] = React.useState<string>('');

    const [cType, setCType] = React.useState<number>(0);

    const [cName, setCName] = React.useState<string>("");
    const [cPhone, setCPhone] = React.useState<string>("");
    const [cEmail, setCEmail] = React.useState<string>("");
    const [cNip, setCNip] = React.useState<string>("");
    const [cRegon, setCRegon] = React.useState<string>("");
    const [cAStreet, setCAStreet] = React.useState<string>("");
    const [cAZip, setCAZip] = React.useState<string>("");
    const [cACity, setCACity] = React.useState<string>("");
    const [cBank, setCBank] = React.useState<string>("");
    const [cAccount, setCAccount] = React.useState<string>("");

    const [cName_OF, setCName_OF] = React.useState<string>("");
    const [cPhone_OF, setCPhone_OF] = React.useState<string>("");
    const [cEmail_OF, setCEmail_OF] = React.useState<string>("");
    const [cAStreet_OF, setCAStreet_OF] = React.useState<string>("");
    const [cAZip_OF, setCAZip_OF] = React.useState<string>("");
    const [cACity_OF, setCACity_OF] = React.useState<string>("");

    const [updateMode, setUpdateMode] = React.useState<boolean>(false);
    const [updateMode_OF, setUpdateMode_OF] = React.useState<boolean>(false);

    const [allData, setAllData] = React.useState<string>('');

    const [changed, setChanged] = React.useState<boolean>(false);

    // ------------------ wczytujemy kontrahentów z bazy -----------
    const [contractors, setContractors] = React.useState([]);

    function getContractors() {
        fetch('https://be.factoorian.pl/contractors/' + comp_id.toString())
            .then(response => {
                let output = response.text();
                console.log(output);
                return output;
            })
            .then(data => {
                setContractors(JSON.parse(data));
                console.log(JSON.parse(data));
            });
    }

    React.useEffect(() => {
        getContractors();
    }, []);


    // ------------------ dodajemy kontrahenta -----------
    function addContractor(cType: number) {
        fetch(`https://be.factoorian.pl/contractors/` + comp_id.toString(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cName, cPhone, cEmail, cNip, cRegon, cAStreet, cAZip, cACity, cBank, cAccount, cType }),
        })
            .then(response => {
                let output = response.text();
                console.log(output);
                return output;
            })
            .then(data => {
                console.log(JSON.parse(data));
                getContractors();
            });
    }

    // ------------------ dodajemy kontrahenta - osoba fizyczna -----------
    function addContractor_OF(cType: number) {
        fetch(`https://be.factoorian.pl/contractors/` + comp_id.toString(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cName_OF, cPhone_OF, cEmail_OF, cAStreet_OF, cAZip_OF, cACity_OF, cType }),
        })
            .then(response => {
                let output = response.text();
                console.log(output);
                return output;
            })
            .then(data => {
                console.log(JSON.parse(data));
                getContractors();
            });
    }


    // ------------------ usuwamy kontrahenta -----------
    function removeGood(id: number) {
        let xbody = JSON.stringify({ id });
        console.log(xbody);
        fetch(`https://be.factoorian.pl/contractors/` + comp_id.toString(), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
        })
            .then(response => {
                let output = response.text();
                console.log(output);
                return output;
            })
            .then(data => {
                console.log(JSON.parse(data));
                getContractors();
            });
    }

    // ------------------ aktualizujemy kontrahenta -----------
    function updateContractor(cType: number) {
        fetch(`https://be.factoorian.pl/contractors/` + comp_id.toString(), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ xRow, cName, cPhone, cEmail, cNip, cRegon, cAStreet, cAZip, cACity, cBank, cAccount, cType }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                getContractors();
            });
    }

    // ------------------ aktualizujemy kontrahenta - osoba fizyczna -----------
    function updateContractor_OF(cType: number) {
        fetch(`https://be.factoorian.pl/contractors/` + comp_id.toString(), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ xRow, cName_OF, cPhone_OF, cEmail_OF, cAStreet_OF, cAZip_OF, cACity_OF, cType }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                getContractors();
            });
    }

    function resetModal() {
        setCName('');
        setCPhone('');
        setCEmail('');
        setCNip('');
        setCRegon('');
        setCAStreet('');
        setCAZip('');
        setCACity('');
        setCBank('');
        setCAccount('');
    }

    function resetModal_OF() {
        setCName_OF('');
        setCPhone_OF('');
        setCEmail_OF('');
        setCAStreet_OF('');
        setCAZip_OF('');
        setCACity_OF('');
    }

    //----------------------------------------------------------------------------------    
    return (
        <Box sx={{ flex: 1, width: '100%' }}>

            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog variant='outlined' role='alertdialog'>
                    <DialogTitle><WarningAmberRoundedIcon sx={{ color: 'darkred' }} /> <Typography textColor='darkred'> Usuwasz pozycję!</Typography></DialogTitle>
                    <Divider />
                    <DialogContent><Typography textAlign='center' textColor='grey'>Czy na pewno chcesz usunąć kontrahenta</Typography><Typography textAlign='center'>{rowText} ?</Typography></DialogContent>
                    <Divider />
                    <Stack spacing={2} direction='row' alignItems='center' justifyContent='center'>

                        <Button
                            color='danger'
                            variant='soft'
                            onClick={() => {
                                setOpen(false);
                            }}>
                            Nie
                        </Button>

                        <Button
                            color='danger'
                            onClick={() => {
                                removeGood(xRow);
                                setOpen(false);
                            }}>
                            Usuń
                        </Button>

                    </Stack>

                </ModalDialog>
            </Modal>

            <Modal open={openNC} onClose={() => {
                setOpenNC(false);
                resetModal();
            }

            }>
                <ModalDialog variant='outlined'>
                    <Card>
                        <Typography level="title-md">Dane podstawowe</Typography>

                        <Stack direction="row" spacing={2}>
                            <FormControl>
                                <FormLabel>Numer Identyfikacji Podatkowej (NIP)</FormLabel>
                                <Input
                                    size="sm"
                                    startDecorator={<CorporateFareRoundedIcon />}
                                    placeholder="NIP"
                                    onChange={(e) => {
                                        setCNip(e.target.value)
                                        setChanged(true)
                                    }}
                                    value={cNip == null ? "" : cNip}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>REGON</FormLabel>
                                <Input
                                    size="sm"
                                    startDecorator={<CorporateFareRoundedIcon />}
                                    placeholder="REGON"
                                    onChange={(e) => {
                                        setCRegon(e.target.value)
                                        setChanged(true)
                                    }}
                                    value={cRegon == null ? "" : cRegon}
                                />
                            </FormControl>
                        </Stack>

                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                            <Stack spacing={1}>
                                <FormLabel>Nazwa firmy</FormLabel>
                                <FormControl
                                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                                >
                                    <Input
                                        size="sm"
                                        placeholder="nazwa"
                                        onChange={(e) => {
                                            setCName(e.target.value)
                                            setChanged(true)
                                        }
                                        }
                                        value={cName == null ? "" : cName}
                                    />
                                </FormControl>
                            </Stack>

                            <Divider />

                            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                                <Typography level="title-md">Dane adresowe i kontaktowe</Typography>
                                <Stack spacing={1}>
                                    <FormLabel>Ulica, numer budynku i lokalu</FormLabel>
                                    <FormControl
                                        sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                                    >
                                        <Input
                                            size="sm"
                                            placeholder="adres"
                                            startDecorator={<PlaceRoundedIcon />}
                                            onChange={(e) => {
                                                setCAStreet(e.target.value)
                                                setChanged(true)
                                            }}
                                            value={cAStreet == null ? "" : cAStreet}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <FormControl>
                                        <FormLabel>Kod pocztowy</FormLabel>
                                        <Input
                                            size="sm"
                                            type="tel"
                                            startDecorator={<LocationCityRoundedIcon />}
                                            placeholder="kod"
                                            onChange={(e) => {
                                                setCAZip(e.target.value)
                                                setChanged(true)
                                            }}
                                            value={cAZip == null ? "" : cAZip}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ flexGrow: 1 }}>
                                        <FormLabel>Miasto</FormLabel>
                                        <Input
                                            size="sm"
                                            type="email"
                                            startDecorator={<LocationCityRoundedIcon />}
                                            placeholder="miasto"
                                            onChange={(e) => {
                                                setCACity(e.target.value)
                                                setChanged(true)
                                            }}
                                            value={cACity == null ? "" : cACity}
                                        //                    sx={{ flexGrow: 1 }}
                                        />
                                    </FormControl>
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                    <FormControl>
                                        <FormLabel>Telefon</FormLabel>
                                        <Input
                                            size="sm"
                                            type="tel"
                                            startDecorator={<ContactPhoneIcon />}
                                            placeholder="telefon"
                                            onChange={(e) => {
                                                setCPhone(e.target.value)
                                                setChanged(true)
                                            }
                                            }
                                            value={cPhone == null ? "" : cPhone}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ flexGrow: 1 }}>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            size="sm"
                                            type="email"
                                            startDecorator={<EmailRoundedIcon />}
                                            placeholder="email"
                                            onChange={(e) => {
                                                setCEmail(e.target.value)
                                                setChanged(true)
                                            }}
                                            value={cEmail == null ? "" : cEmail}
                                        //                    sx={{ flexGrow: 1 }}
                                        />
                                    </FormControl>
                                </Stack>

                            </Stack>

                            <Divider />

                            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                                <Typography level="title-md">Dane rozliczeniowe</Typography>

                                <Stack direction="row" spacing={2}>
                                    <FormControl>
                                        <FormLabel>Nazwa banku</FormLabel>
                                        <Input
                                            size="sm"
                                            type="text"
                                            startDecorator={<AccountBalanceRoundedIcon />}
                                            placeholder="bank"
                                            onChange={(e) => {
                                                setCBank(e.target.value)
                                                setChanged(true)
                                            }}
                                            value={cBank == null ? "" : cBank}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ flexGrow: 1 }}>
                                        <FormLabel>Numer konta</FormLabel>
                                        <Input
                                            size="sm"
                                            startDecorator={<AccountBalanceRoundedIcon />}
                                            placeholder="numer"
                                            onChange={(e) => {
                                                setCAccount(e.target.value)
                                                setChanged(true);
                                            }}
                                            value={cAccount == null ? "" : cAccount}
                                        //                    sx={{ flexGrow: 1 }}
                                        />
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </Stack>


                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                <Button
                                    disabled={!((cNip !== '') && (cName !== '') && (cAStreet !== '') && (cAZip !== '') && (cACity !== ''))}
                                    size="sm"
                                    variant="solid"
                                    onClick={() => {
                                        setChanged(false);
                                        setCType(1);
                                        updateMode ? updateContractor(1) : addContractor(1);
                                        setOpenNC(false);
                                        resetModal();
                                    }
                                    }
                                >
                                    {updateMode ? 'Aktualizuj dane' : 'Dodaj kontrahenta'}
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>

                </ModalDialog>
            </Modal>

            <Modal open={openNC_OF} onClose={() => {
                setOpenNC_OF(false);
                resetModal_OF();
            }

            }>
                <ModalDialog variant='outlined'>
                    <Card>
                        <Typography level="title-md">Dane podstawowe</Typography>

                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>imię i nazwisko</FormLabel>
                                <Input
                                    size="sm"
                                    startDecorator={<BoyIcon />}
                                    placeholder="Imię i nazwisko"
                                    onChange={(e) => {
                                        setCName_OF(e.target.value)
                                        setChanged(true)
                                    }}
                                    value={cName_OF == null ? "" : cName_OF}
                                />
                            </FormControl>
                        </Stack>

                        <Divider />

                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                            <Typography level="title-md">Dane adresowe i kontaktowe</Typography>
                            <Stack spacing={1}>
                                <FormLabel>Ulica, numer budynku i lokalu</FormLabel>
                                <FormControl
                                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                                >
                                    <Input
                                        size="sm"
                                        placeholder="adres"
                                        startDecorator={<PlaceRoundedIcon />}
                                        onChange={(e) => {
                                            setCAStreet_OF(e.target.value)
                                            setChanged(true)
                                        }}
                                        value={cAStreet_OF == null ? "" : cAStreet_OF}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControl>
                                    <FormLabel>Kod pocztowy</FormLabel>
                                    <Input
                                        size="sm"
                                        type="tel"
                                        startDecorator={<LocationCityRoundedIcon />}
                                        placeholder="kod"
                                        onChange={(e) => {
                                            setCAZip_OF(e.target.value)
                                            setChanged(true)
                                        }}
                                        value={cAZip_OF == null ? "" : cAZip_OF}
                                    />
                                </FormControl>
                                <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel>Miasto</FormLabel>
                                    <Input
                                        size="sm"
                                        type="email"
                                        startDecorator={<LocationCityRoundedIcon />}
                                        placeholder="miasto"
                                        onChange={(e) => {
                                            setCACity_OF(e.target.value)
                                            setChanged(true)
                                        }}
                                        value={cACity_OF == null ? "" : cACity_OF}
                                    //                    sx={{ flexGrow: 1 }}
                                    />
                                </FormControl>
                            </Stack>

                            <Stack direction="row" spacing={2}>
                                <FormControl>
                                    <FormLabel>Telefon</FormLabel>
                                    <Input
                                        size="sm"
                                        type="tel"
                                        startDecorator={<ContactPhoneIcon />}
                                        placeholder="telefon"
                                        onChange={(e) => {
                                            setCPhone_OF(e.target.value)
                                            setChanged(true)
                                        }
                                        }
                                        value={cPhone_OF == null ? "" : cPhone_OF}
                                    />
                                </FormControl>
                                <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        size="sm"
                                        type="email"
                                        startDecorator={<EmailRoundedIcon />}
                                        placeholder="email"
                                        onChange={(e) => {
                                            setCEmail_OF(e.target.value)
                                            setChanged(true)
                                        }}
                                        value={cEmail_OF == null ? "" : cEmail_OF}
                                    //                    sx={{ flexGrow: 1 }}
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>


                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                <Button
                                    disabled={!((cName_OF !== '') && (cAStreet_OF !== '') && (cAZip_OF !== '') && (cACity_OF !== ''))}
                                    size="sm"
                                    variant="solid"
                                    onClick={() => {
                                        setChanged(false);
                                        setCType(2);
                                        updateMode ? updateContractor_OF(2) : addContractor_OF(2);
                                        setOpenNC_OF(false);
                                        resetModal_OF();
                                    }
                                    }
                                >
                                    {updateMode ? 'Aktualizuj dane' : 'Dodaj kontrahenta'}
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>

                </ModalDialog>
            </Modal>

            <Box
                sx={{
                    position: 'sticky',
                    top: { sm: -100, md: -110 },
                    bgcolor: 'background.body',
                    //         zIndex: 9995,
                }}
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: { sm: -100, md: -110 },
                        bgcolor: 'background.body',
                        zIndex: 9995,
                    }}
                >
                    <Box sx={{ px: { xs: 2, md: 6 } }}>
                        <Stack direction='row' spacing={1}>
                            <HomeRoundedIcon color='primary' fontSize='small' />
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Biblioteki
                            </Typography>
                            <ChevronRightRoundedIcon color='primary' fontSize="small" />
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Kontrahenci
                            </Typography>
                        </Stack>
                    </Box>
                </Box>

                <Box sx={{ px: { xs: 2, md: 6 } }}>
                    <Typography level="h3" component="h1" sx={{ mt: 1, mb: 2 }}>
                        Lista kontrahentów
                    </Typography>
                </Box>
            </Box>
            <Stack
                spacing={4}
                sx={{
                    display: 'flex',
                    //        maxWidth: '1200px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                }}
            >

                <Card>

                    <Stack direction='row' width='100%'>
                        <Stack width='50%'>
                            <Typography level="title-md">Zestawienie</Typography>
                        </Stack>
                        <Stack width='50%' alignItems='end' justifyContent='right'>
                            <Dropdown>
                                <MenuButton startDecorator={<AddRoundedIcon />} variant='outlined' sx={{ maxWidth: 200, textAlign: 'center' }}>Nowy kontrahent</MenuButton>

                                {/*                            <Button
                                variant='outlined'
                                onClick={() => {
                                    setUpdateMode(false);
                                    setOpenNC(true);
                                  }
                                }
                                sx={{ maxWidth: 200, textAlign: 'center'}}
                            >
                                <AddRoundedIcon/> Nowy kontrahent
                            </Button>       */}
                                <Menu>
                                    <MenuItem
                                        onClick={() => {
                                            setUpdateMode(false);
                                            setOpenNC(true);
                                        }}
                                    >
                                        <CorporateFareRoundedIcon />
                                        Przedsiębiorca
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setUpdateMode(false);
                                            setOpenNC_OF(true);
                                        }}
                                    >
                                        <BoyIcon />
                                        Osoba fizyczna
                                    </MenuItem>
                                </Menu>
                            </Dropdown>
                        </Stack>
                    </Stack>

                    <Divider />

                    <Stack spacing={2} sx={{ my: 1 }}>

                        <Box>
                            <Table
                                aria-labelledby="tableTitle"
                                stickyHeader
                                hoverRow
                                sx={{
                                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                                    '--Table-headerUnderlineThickness': '1px',
                                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                    '--TableCell-paddingY': '4px',
                                    '--TableCell-paddingX': '8px',
                                }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '5%', textAlign: 'center', padding: '12px 6px' }}>&nbsp;</th>
                                        <th style={{ width: '15%', textAlign: 'center', padding: '12px 6px' }}>NIP</th>
                                        <th style={{ width: '40%', textAlign: 'left', padding: '12px 6px' }}>Nazwa</th>
                                        <th style={{ width: '40%', textAlign: 'left', padding: '12px 6px' }}>Adres</th>
                                        <th style={{ width: 90, textAlign: 'center', padding: '12px 6px' }}>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contractors.map((row) => (
                                        <tr
                                            key={row['contr_id']}
                                        >
                                            <td style={{ textAlign: 'center' }}>{(row['contr_type'] === 1) ? <CorporateFareRoundedIcon sx={{ fontSize: '18px' }} /> : <BoyIcon sx={{ fontSize: '18px' }} />}</td>            <td style={{ textAlign: 'center' }}>{row['nip']}</td>
                                            <td style={{ textAlign: 'left' }}><Typography level="body-xs">{row['name']}</Typography></td>
                                            <td style={{ textAlign: 'left' }}><Typography level="body-xs">{row['address_zip']} {row['address_city']}, {row['address_street']}</Typography></td>
                                            <td style={{ textAlign: 'center' }}>
                                                <IconButton onClick={
                                                    () => {


                                                        setUpdateMode(true);
                                                        if (row['contr_type'] === 1) {
                                                            setRow(row['contr_id']);
                                                            setCName(row['name']);
                                                            setCPhone(row['phone']);
                                                            setCEmail(row['email']);
                                                            setCNip(row['nip']);
                                                            setCRegon(row['regon']);
                                                            setCAStreet(row['address_street']);
                                                            setCAZip(row['address_zip']);
                                                            setCACity(row['address_city']);
                                                            setCBank(row['bank']);
                                                            setCAccount(row['account']);  
                                                            
                                                            setOpenNC(true);
                                                        } else {
                                                            setRow(row['contr_id']);
                                                            setCName_OF(row['name']);
                                                            setCAStreet_OF(row['address_street']);
                                                            setCAZip_OF(row['address_zip']);
                                                            setCACity_OF(row['address_city']);                                                           
                                                            setCPhone_OF(row['phone']);
                                                            setCEmail_OF(row['email']);

                                                            setOpenNC_OF(true);
                                                        }
                                                    }
                                                }>
                                                    <EditRoundedIcon sx={{ "&:hover": { color: "blue" }, fontSize: '18px' }} />
                                                </IconButton>

                                                <IconButton onClick={
                                                    () => {
                                                        setRow(row['contr_id']);
                                                        setRowText(row['name'])
                                                        setOpen(true);
                                                    }
                                                }>
                                                    <HighlightOffRoundedIcon sx={{ "&:hover": { color: "red" }, fontSize: '18px' }} />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Box>
                    </Stack>
                </Card>
            </Stack>
        </Box>
    );
}